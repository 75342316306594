import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Globals } from '../../globals';

@Component({
	selector: 'app-landing-aliados',
	templateUrl: './landing-aliados.component.html',
	styleUrls: ['./landing-aliados.component.scss']
})
export class LandingAliadosComponent implements OnInit {
    @Input('inHome') inHome
	marcas = []

	constructor(
		public http: HttpClient,
		public globals: Globals,
	) { }

	ngOnInit(): void {
		this.getMarcasActivas()
	}
	getMarcasActivas (){
        
		this.http.get('https://ie-vie.com:3000/api/marcas/activas').subscribe( (resData: any)=>{
			this.marcas = resData
			this.marcas = this.marcas.slice(0, 15)
		})
	}

}
