import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { AppService } from '../../app.service';
import { Router } from '@angular/router';

export interface DialogDataCiudades {
	url_redirect: string,
}
@Component({
	selector: 'app-modal-ciudades',
	templateUrl: 'modal-ciudades.component.html',
})
export class ModalCiudadesComponent {
	ciudades
	ciudadActual

	constructor(
		public dialogRef: MatDialogRef<ModalCiudadesComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any, // DialogDataCiudades,
		
		private appService: AppService,
		private router: Router,
	){
    // console.log(this.data)
		this.ciudadActual = this.appService.getCiudadActual()// this.appService.ciudadActual
		this.ciudades = this.appService.puntosVenta;
	}
	ngOnInit() {
    
	}


	cerrarModal() {
		if (!this.ciudadActual) {
			this.appService.setCiudad(this.ciudades[0])
			this.router.navigate(['/bogota'])
		}
		localStorage.setItem('ciudadActual', JSON.stringify(this.appService.getCiudadActual().plano_name));
		this.dialogRef.close(this.data);
	}
	
	setCiudadActual(event){
		this.appService.setCiudad(event.value)
    this.data = this.ciudadActual
		this.cerrarModal();		
	}
	/* setCiudadBogota (){
		localStorage.setItem('ciudadActual', 'bogota');
		this.appService.setCiudadActualConPlanoName('bogota');
		this.router.navigate(['/bogota'])
		this.dialogRef.close();
	} */
}