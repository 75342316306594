import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Category, Product, TipoDireccion } from './app.models';
import { Producto } from './models/producto';
import { Sede } from './models/sede';
import { Globals } from './globals';

// notificaciones push
// const noti = require('push.js') //  * from 'push.js';
import { UsuarioService } from './services/usuario.service';
import { Marca } from './models/marca';
import { MarcaService } from './services/marca.service';
import { AbstractControl } from '@angular/forms';
import { CustomSnackbarComponent } from './shared/custom-snackbar/custom-snackbar.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalCiudadesComponent } from './shared/modal-ciudades/modal-ciudades.component';

export class Data {
	constructor(
		public categories: Category[],
		public compareList: Producto[],
		public wishList: Producto[],
		public cartList: Producto[] = JSON.parse(localStorage.getItem('carrito')) ? JSON.parse(localStorage.getItem('carrito')) : [],
		public totalPrice: number,
		public totalCartCount: number,
		public sedes: any,
		public tiposDirecciones: TipoDireccion[],
		public productos: Producto[],
		public marcas: Marca[],
		public marcasA: Marca[]
	) {
	}
}

@Injectable()
export class AppService {
	// geolocalización
	public lat: number
	public lng: number
	public misCoordenadas = {
		latitud: 0,
		longitud: 0
	}
	public closest
	
	public ciudadActual:any = { plano_name: 'panama'};
	public contenido = new BehaviorSubject<any>(this.ciudadActual);
	public compartidor = this.contenido.asObservable();

	// rest
	restItems: any;
	public productosCiudad
	public mensajeCarga = 'Cargando ...';
	public correosNotificacion = [];

	//modales
	dialogRefModalCotizaciones

	public Data = new Data(
		[], // categories
		[], // compareList apikey google maps   --->   AIzaSyDRESvzdM9a4lTuBjGlPRTwyRusBippy7U
		[],  // wishList
		JSON.parse(localStorage.getItem('carrito')) ? JSON.parse(localStorage.getItem('carrito')) : [],  // cartList
		null, // totalPrice,
		0, // totalCartCount
		[], // sedes
		[], // TiposDreccion
		[], // productos
		[], // marcas
		[], // marcas activas
	);
	public url = 'assets/data/';
	public usuarioSAP;

	// variable en app.service y en geocode.service
	public puntosVenta = [
		{
			id: 50,
			ciudad: "Panamá pacific",
			departamento: "Panamá",
			correos: ["lptrillos@iegrupo.co", "jahernandez@iegrupo.co"],
			bodegas: [],
			plano_name: "panama",
			latitud: 8.926163651340396,
			longitud: -79.59349932000248,
			telefono: '+507 201-7969',
			direccion: 'Edificio 3855, Panamá Pacífico Boulevard Panamá Pacífico'
		}
	];

	public fondo = `https://banners-ie.s3.amazonaws.com/fondo/fondoapp`;

	constructor(
		public http: HttpClient,
		public snackBar: MatSnackBar,
		private globals: Globals,
		private usuarioService: UsuarioService,
		public marcaService: MarcaService,
		private router: Router,
		private modalCotizaciones: MatDialog,
		public hojaAux: MatDialog
	) {
		this.Data.sedes = this.puntosVenta
		
		this.Data.cartList = JSON.parse(localStorage.getItem('carrito')) ? JSON.parse(localStorage.getItem('carrito')) : [];
		this.cargarCarrito();
		// this.setCiudadActualConPlanoName('panama')
	}

	private readonly ciudadCercana$ = new BehaviorSubject<any>({plano_name: 'panama'})
	get ciudadCercana() {
		return this.ciudadCercana$.asObservable()
	}

	setCiudad (data){
		let arregloRutas = [
			this.router.isActive('/', true),
			this.router.isActive('/bogota', true),
			this.router.isActive('/bucaramanga', true),
			this.router.isActive('/cali', true),
			this.router.isActive('/cartagena', true),
			this.router.isActive('/cucuta', true),
			this.router.isActive('/ibague', true),
			this.router.isActive('/manizales', true),
			this.router.isActive('/monteria', true),
			this.router.isActive('/mosquera', true),
			this.router.isActive('/neiva', true),
			this.router.isActive('/pereira', true),
			this.router.isActive('/villavicencio', true),
		]
		let homeDeCiudad = arregloRutas.includes(data.plano_name)
		this.contenido.next(data)
		this.ciudadActual = data;
		if (homeDeCiudad){
			this.router.navigate(['/'+data.plano_name]);
		}
	}
	getCategories(): Observable<Category[]> {
		return this.http.get<Category[]>(this.globals.urlApi + 'categorias');
	}
	getCiudadesIE(): Observable<any> {
		return this.http.get<any>(this.url + 'ieciudades.json');
	}
	getProducts(type): Observable<Product[]> {
		return this.http.get<Product[]>(this.url + type + '-products.json');
	}
	getProductById(id): Observable<Product> {
		return this.http.get<Product>(this.url + 'product-' + id + '.json');
	}
	/* getBanners(): Observable<any[]> {
		return this.http.get<any[]>(this.url + 'banners.json');
	} */
	getSedes(): Observable<any[]> {
		return this.http.get<any>(this.globals.urlApi + 'sedes-bodegas');
		// return this.http.get<any[]>(this.url + 'sedes.json');
	}
	// otro
	getUsuarioSAP(correo) {
		this.usuarioService.getUsuarioSAP(correo).subscribe((res: any) => {
			this.usuarioSAP = res;
		})
	}
	addToCompare(product: Producto) {
		let message, status;
		if (this.Data.compareList.filter(item => item.idsap === product.idsap)[0]) {
			message = 'El producto ' + product.nombre + ' ya fue agregado a la lista de comparación.';
			status = 'error';
		} else {
			this.Data.compareList.push(product);
			message = 'Producto agregado a la lista de comparación.';
			status = 'success';
		}
		this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 1000 });
	}
	addToWishList(product: Producto) {
		let message, status;
		if (this.Data.wishList.filter(item => item.idsap === product.idsap)[0]) {
			message = 'El producto ' + product.nombre + ' ya fue agregado a la cotización.';
			status = 'error';
		} else {
			this.Data.wishList.push(product);
			message = 'El producto ' + product.nombre + ' ha sido agregado a la cotización.';
			status = 'success';
		}
		this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 2000 });
	}
	addToCart(product: Producto) {
		let message, status;

		this.Data.totalPrice = null;
		this.Data.totalCartCount = null;

		if (this.Data.cartList.filter(item => item.idsap === product.idsap)[0]) {
			const item = this.Data.cartList.filter(objeto => objeto.idsap === product.idsap)[0];
			item.enCarrito = product.enCarrito;
		} else {
			this.Data.cartList.push(product);
		}
		this.Data.cartList.forEach(producto => {
			this.Data.totalPrice = this.Data.totalPrice + (producto.enCarrito * producto.precioecommerce);
			this.Data.totalCartCount = this.Data.totalCartCount + parseInt(producto.enCarrito.toString(), 0);
		});
		this.salvarCarrito();
		message = product.nombre + ' se agregó al carrito de compras.';
		status = 'success';
		this.verMensaje(message, status)
		// this.snackBar.open(message, 'x', { panelClass: [status], verticalPosition: 'top', duration: -1 });
		// this.snackBar.openFromComponent(CustomSnackbarComponent, {panelClass: 'success', verticalPosition: 'top', duration: -1}).onAction()
	}
	verMensaje(mensaje: string, panelClass: string) {
		this.snackBar.openFromComponent(CustomSnackbarComponent, {
			data: mensaje,
			panelClass: panelClass,
			duration: -1,
			verticalPosition: 'top'
		})
	}
	resetProductCartCount(product: Producto) {
		product.enCarrito = 0;
		const compareProduct = this.Data.compareList.filter(item => item.id === product.id)[0];
		if (compareProduct) {
			compareProduct.enCarrito = 0;
		}
		const wishProduct = this.Data.wishList.filter(item => item.id === product.id)[0];
		if (wishProduct) {
			wishProduct.enCarrito = 0;
		}
	}
	getCountries() {
		return [
			{ name: 'Afghanistan', code: 'AF' },
			{ name: 'Aland Islands', code: 'AX' },
			{ name: 'Albania', code: 'AL' },
			{ name: 'Algeria', code: 'DZ' },
			{ name: 'American Samoa', code: 'AS' },
			{ name: 'AndorrA', code: 'AD' },
			{ name: 'Angola', code: 'AO' },
			{ name: 'Anguilla', code: 'AI' },
			{ name: 'Antarctica', code: 'AQ' },
			{ name: 'Antigua and Barbuda', code: 'AG' },
			{ name: 'Argentina', code: 'AR' },
			{ name: 'Armenia', code: 'AM' },
			{ name: 'Aruba', code: 'AW' },
			{ name: 'Australia', code: 'AU' },
			{ name: 'Austria', code: 'AT' },
			{ name: 'Azerbaijan', code: 'AZ' },
			{ name: 'Bahamas', code: 'BS' },
			{ name: 'Bahrain', code: 'BH' },
			{ name: 'Bangladesh', code: 'BD' },
			{ name: 'Barbados', code: 'BB' },
			{ name: 'Belarus', code: 'BY' },
			{ name: 'Belgium', code: 'BE' },
			{ name: 'Belize', code: 'BZ' },
			{ name: 'Benin', code: 'BJ' },
			{ name: 'Bermuda', code: 'BM' },
			{ name: 'Bhutan', code: 'BT' },
			{ name: 'Bolivia', code: 'BO' },
			{ name: 'Bosnia and Herzegovina', code: 'BA' },
			{ name: 'Botswana', code: 'BW' },
			{ name: 'Bouvet Island', code: 'BV' },
			{ name: 'Brazil', code: 'BR' },
			{ name: 'British Indian Ocean Territory', code: 'IO' },
			{ name: 'Brunei Darussalam', code: 'BN' },
			{ name: 'Bulgaria', code: 'BG' },
			{ name: 'Burkina Faso', code: 'BF' },
			{ name: 'Burundi', code: 'BI' },
			{ name: 'Cambodia', code: 'KH' },
			{ name: 'Cameroon', code: 'CM' },
			{ name: 'Canada', code: 'CA' },
			{ name: 'Cape Verde', code: 'CV' },
			{ name: 'Cayman Islands', code: 'KY' },
			{ name: 'Central African Republic', code: 'CF' },
			{ name: 'Chad', code: 'TD' },
			{ name: 'Chile', code: 'CL' },
			{ name: 'China', code: 'CN' },
			{ name: 'Christmas Island', code: 'CX' },
			{ name: 'Cocos (Keeling) Islands', code: 'CC' },
			{ name: 'Colombia', code: 'CO' },
			{ name: 'Comoros', code: 'KM' },
			{ name: 'Congo', code: 'CG' },
			{ name: 'Congo, The Democratic Republic of the', code: 'CD' },
			{ name: 'Cook Islands', code: 'CK' },
			{ name: 'Costa Rica', code: 'CR' },
			{ name: 'Cote D\'Ivoire', code: 'CI' },
			{ name: 'Croatia', code: 'HR' },
			{ name: 'Cuba', code: 'CU' },
			{ name: 'Cyprus', code: 'CY' },
			{ name: 'Czech Republic', code: 'CZ' },
			{ name: 'Denmark', code: 'DK' },
			{ name: 'Djibouti', code: 'DJ' },
			{ name: 'Dominica', code: 'DM' },
			{ name: 'Dominican Republic', code: 'DO' },
			{ name: 'Ecuador', code: 'EC' },
			{ name: 'Egypt', code: 'EG' },
			{ name: 'El Salvador', code: 'SV' },
			{ name: 'Equatorial Guinea', code: 'GQ' },
			{ name: 'Eritrea', code: 'ER' },
			{ name: 'Estonia', code: 'EE' },
			{ name: 'Ethiopia', code: 'ET' },
			{ name: 'Falkland Islands (Malvinas)', code: 'FK' },
			{ name: 'Faroe Islands', code: 'FO' },
			{ name: 'Fiji', code: 'FJ' },
			{ name: 'Finland', code: 'FI' },
			{ name: 'France', code: 'FR' },
			{ name: 'French Guiana', code: 'GF' },
			{ name: 'French Polynesia', code: 'PF' },
			{ name: 'French Southern Territories', code: 'TF' },
			{ name: 'Gabon', code: 'GA' },
			{ name: 'Gambia', code: 'GM' },
			{ name: 'Georgia', code: 'GE' },
			{ name: 'Germany', code: 'DE' },
			{ name: 'Ghana', code: 'GH' },
			{ name: 'Gibraltar', code: 'GI' },
			{ name: 'Greece', code: 'GR' },
			{ name: 'Greenland', code: 'GL' },
			{ name: 'Grenada', code: 'GD' },
			{ name: 'Guadeloupe', code: 'GP' },
			{ name: 'Guam', code: 'GU' },
			{ name: 'Guatemala', code: 'GT' },
			{ name: 'Guernsey', code: 'GG' },
			{ name: 'Guinea', code: 'GN' },
			{ name: 'Guinea-Bissau', code: 'GW' },
			{ name: 'Guyana', code: 'GY' },
			{ name: 'Haiti', code: 'HT' },
			{ name: 'Heard Island and Mcdonald Islands', code: 'HM' },
			{ name: 'Holy See (Vatican City State)', code: 'VA' },
			{ name: 'Honduras', code: 'HN' },
			{ name: 'Hong Kong', code: 'HK' },
			{ name: 'Hungary', code: 'HU' },
			{ name: 'Iceland', code: 'IS' },
			{ name: 'India', code: 'IN' },
			{ name: 'Indonesia', code: 'ID' },
			{ name: 'Iran, Islamic Republic Of', code: 'IR' },
			{ name: 'Iraq', code: 'IQ' },
			{ name: 'Ireland', code: 'IE' },
			{ name: 'Isle of Man', code: 'IM' },
			{ name: 'Israel', code: 'IL' },
			{ name: 'Italy', code: 'IT' },
			{ name: 'Jamaica', code: 'JM' },
			{ name: 'Japan', code: 'JP' },
			{ name: 'Jersey', code: 'JE' },
			{ name: 'Jordan', code: 'JO' },
			{ name: 'Kazakhstan', code: 'KZ' },
			{ name: 'Kenya', code: 'KE' },
			{ name: 'Kiribati', code: 'KI' },
			{ name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
			{ name: 'Korea, Republic of', code: 'KR' },
			{ name: 'Kuwait', code: 'KW' },
			{ name: 'Kyrgyzstan', code: 'KG' },
			{ name: 'Lao People\'S Democratic Republic', code: 'LA' },
			{ name: 'Latvia', code: 'LV' },
			{ name: 'Lebanon', code: 'LB' },
			{ name: 'Lesotho', code: 'LS' },
			{ name: 'Liberia', code: 'LR' },
			{ name: 'Libyan Arab Jamahiriya', code: 'LY' },
			{ name: 'Liechtenstein', code: 'LI' },
			{ name: 'Lithuania', code: 'LT' },
			{ name: 'Luxembourg', code: 'LU' },
			{ name: 'Macao', code: 'MO' },
			{ name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
			{ name: 'Madagascar', code: 'MG' },
			{ name: 'Malawi', code: 'MW' },
			{ name: 'Malaysia', code: 'MY' },
			{ name: 'Maldives', code: 'MV' },
			{ name: 'Mali', code: 'ML' },
			{ name: 'Malta', code: 'MT' },
			{ name: 'Marshall Islands', code: 'MH' },
			{ name: 'Martinique', code: 'MQ' },
			{ name: 'Mauritania', code: 'MR' },
			{ name: 'Mauritius', code: 'MU' },
			{ name: 'Mayotte', code: 'YT' },
			{ name: 'Mexico', code: 'MX' },
			{ name: 'Micronesia, Federated States of', code: 'FM' },
			{ name: 'Moldova, Republic of', code: 'MD' },
			{ name: 'Monaco', code: 'MC' },
			{ name: 'Mongolia', code: 'MN' },
			{ name: 'Montserrat', code: 'MS' },
			{ name: 'Morocco', code: 'MA' },
			{ name: 'Mozambique', code: 'MZ' },
			{ name: 'Myanmar', code: 'MM' },
			{ name: 'Namibia', code: 'NA' },
			{ name: 'Nauru', code: 'NR' },
			{ name: 'Nepal', code: 'NP' },
			{ name: 'Netherlands', code: 'NL' },
			{ name: 'Netherlands Antilles', code: 'AN' },
			{ name: 'New Caledonia', code: 'NC' },
			{ name: 'New Zealand', code: 'NZ' },
			{ name: 'Nicaragua', code: 'NI' },
			{ name: 'Niger', code: 'NE' },
			{ name: 'Nigeria', code: 'NG' },
			{ name: 'Niue', code: 'NU' },
			{ name: 'Norfolk Island', code: 'NF' },
			{ name: 'Northern Mariana Islands', code: 'MP' },
			{ name: 'Norway', code: 'NO' },
			{ name: 'Oman', code: 'OM' },
			{ name: 'Pakistan', code: 'PK' },
			{ name: 'Palau', code: 'PW' },
			{ name: 'Palestinian Territory, Occupied', code: 'PS' },
			{ name: 'Panama', code: 'PA' },
			{ name: 'Papua New Guinea', code: 'PG' },
			{ name: 'Paraguay', code: 'PY' },
			{ name: 'Peru', code: 'PE' },
			{ name: 'Philippines', code: 'PH' },
			{ name: 'Pitcairn', code: 'PN' },
			{ name: 'Poland', code: 'PL' },
			{ name: 'Portugal', code: 'PT' },
			{ name: 'Puerto Rico', code: 'PR' },
			{ name: 'Qatar', code: 'QA' },
			{ name: 'Reunion', code: 'RE' },
			{ name: 'Romania', code: 'RO' },
			{ name: 'Russian Federation', code: 'RU' },
			{ name: 'RWANDA', code: 'RW' },
			{ name: 'Saint Helena', code: 'SH' },
			{ name: 'Saint Kitts and Nevis', code: 'KN' },
			{ name: 'Saint Lucia', code: 'LC' },
			{ name: 'Saint Pierre and Miquelon', code: 'PM' },
			{ name: 'Saint Vincent and the Grenadines', code: 'VC' },
			{ name: 'Samoa', code: 'WS' },
			{ name: 'San Marino', code: 'SM' },
			{ name: 'Sao Tome and Principe', code: 'ST' },
			{ name: 'Saudi Arabia', code: 'SA' },
			{ name: 'Senegal', code: 'SN' },
			{ name: 'Serbia and Montenegro', code: 'CS' },
			{ name: 'Seychelles', code: 'SC' },
			{ name: 'Sierra Leone', code: 'SL' },
			{ name: 'Singapore', code: 'SG' },
			{ name: 'Slovakia', code: 'SK' },
			{ name: 'Slovenia', code: 'SI' },
			{ name: 'Solomon Islands', code: 'SB' },
			{ name: 'Somalia', code: 'SO' },
			{ name: 'South Africa', code: 'ZA' },
			{ name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
			{ name: 'Spain', code: 'ES' },
			{ name: 'Sri Lanka', code: 'LK' },
			{ name: 'Sudan', code: 'SD' },
			{ name: 'Suriname', code: 'SR' },
			{ name: 'Svalbard and Jan Mayen', code: 'SJ' },
			{ name: 'Swaziland', code: 'SZ' },
			{ name: 'Sweden', code: 'SE' },
			{ name: 'Switzerland', code: 'CH' },
			{ name: 'Syrian Arab Republic', code: 'SY' },
			{ name: 'Taiwan, Province of China', code: 'TW' },
			{ name: 'Tajikistan', code: 'TJ' },
			{ name: 'Tanzania, United Republic of', code: 'TZ' },
			{ name: 'Thailand', code: 'TH' },
			{ name: 'Timor-Leste', code: 'TL' },
			{ name: 'Togo', code: 'TG' },
			{ name: 'Tokelau', code: 'TK' },
			{ name: 'Tonga', code: 'TO' },
			{ name: 'Trinidad and Tobago', code: 'TT' },
			{ name: 'Tunisia', code: 'TN' },
			{ name: 'Turkey', code: 'TR' },
			{ name: 'Turkmenistan', code: 'TM' },
			{ name: 'Turks and Caicos Islands', code: 'TC' },
			{ name: 'Tuvalu', code: 'TV' },
			{ name: 'Uganda', code: 'UG' },
			{ name: 'Ukraine', code: 'UA' },
			{ name: 'United Arab Emirates', code: 'AE' },
			{ name: 'United Kingdom', code: 'GB' },
			{ name: 'United States', code: 'US' },
			{ name: 'United States Minor Outlying Islands', code: 'UM' },
			{ name: 'Uruguay', code: 'UY' },
			{ name: 'Uzbekistan', code: 'UZ' },
			{ name: 'Vanuatu', code: 'VU' },
			{ name: 'Venezuela', code: 'VE' },
			{ name: 'Viet Nam', code: 'VN' },
			{ name: 'Virgin Islands, British', code: 'VG' },
			{ name: 'Virgin Islands, U.S.', code: 'VI' },
			{ name: 'Wallis and Futuna', code: 'WF' },
			{ name: 'Western Sahara', code: 'EH' },
			{ name: 'Yemen', code: 'YE' },
			{ name: 'Zambia', code: 'ZM' },
			{ name: 'Zimbabwe', code: 'ZW' }
		];
	}
	getMonths() {
		return [
			{ value: '01', name: 'Enero' },
			{ value: '02', name: 'Febrero' },
			{ value: '03', name: 'Marzo' },
			{ value: '04', name: 'Abril' },
			{ value: '05', name: 'Mayo' },
			{ value: '06', name: 'Junio' },
			{ value: '07', name: 'Julio' },
			{ value: '08', name: 'Agosto' },
			{ value: '09', name: 'Septiembre' },
			{ value: '10', name: 'Octubre' },
			{ value: '11', name: 'Noviembre' },
			{ value: '12', name: 'Diciembre' }
		];
	}
	getAcentos() {
		return {
			'A': 'a',
			'ẚ': 'a',
			'Á': 'a',
			'á': 'a',
			'À': 'a',
			'à': 'a',
			'Ă': 'a',
			'ă': 'a',
			'Ắ': 'a',
			'ắ': 'a',
			'Ằ': 'a',
			'ằ': 'a',
			'Ẵ': 'a',
			'ẵ': 'a',
			'Ẳ': 'a',
			'ẳ': 'a',
			'Â': 'a',
			'â': 'a',
			'Ấ': 'a',
			'ấ': 'a',
			'Ầ': 'a',
			'ầ': 'a',
			'Ẫ': 'a',
			'ẫ': 'a',
			'Ẩ': 'a',
			'ẩ': 'a',
			'Ǎ': 'a',
			'ǎ': 'a',
			'Å': 'a',
			'å': 'a',
			'Ǻ': 'a',
			'ǻ': 'a',
			'Ä': 'a',
			'ä': 'a',
			'Ǟ': 'a',
			'ǟ': 'a',
			'Ã': 'a',
			'ã': 'a',
			'Ȧ': 'a',
			'ȧ': 'a',
			'Ǡ': 'a',
			'ǡ': 'a',
			'Ą': 'a',
			'ą': 'a',
			'Ā': 'a',
			'ā': 'a',
			'Ả': 'a',
			'ả': 'a',
			'Ȁ': 'a',
			'ȁ': 'a',
			'Ȃ': 'a',
			'ȃ': 'a',
			'Ạ': 'a',
			'ạ': 'a',
			'Ặ': 'a',
			'ặ': 'a',
			'Ậ': 'a',
			'ậ': 'a',
			'Ḁ': 'a',
			'ḁ': 'a',
			'Ⱥ': 'a',
			'ⱥ': 'a',
			'Ǽ': 'a',
			'ǽ': 'a',
			'Ǣ': 'a',
			'ǣ': 'a',
			'Ḃ': 'b',
			'ḃ': 'b',
			'Ḅ': 'b',
			'ḅ': 'b',
			'Ḇ': 'b',
			'ḇ': 'b',
			'Ƀ': 'b',
			'ƀ': 'b',
			'ᵬ': 'b',
			'Ɓ': 'b',
			'ɓ': 'b',
			'Ƃ': 'b',
			'ƃ': 'b',
			'Ć': 'c',
			'ć': 'c',
			'Ĉ': 'c',
			'ĉ': 'c',
			'Č': 'c',
			'č': 'c',
			'Ċ': 'c',
			'ċ': 'c',
			'Ç': 'c',
			'ç': 'c',
			'Ḉ': 'c',
			'ḉ': 'c',
			'Ȼ': 'c',
			'ȼ': 'c',
			'Ƈ': 'c',
			'ƈ': 'c',
			'ɕ': 'c',
			'Ď': 'd',
			'ď': 'd',
			'Ḋ': 'd',
			'ḋ': 'd',
			'Ḑ': 'd',
			'ḑ': 'd',
			'Ḍ': 'd',
			'ḍ': 'd',
			'Ḓ': 'd',
			'ḓ': 'd',
			'Ḏ': 'd',
			'ḏ': 'd',
			'Đ': 'd',
			'đ': 'd',
			'ᵭ': 'd',
			'Ɖ': 'd',
			'ɖ': 'd',
			'Ɗ': 'd',
			'ɗ': 'd',
			'Ƌ': 'd',
			'ƌ': 'd',
			'ȡ': 'd',
			'ð': 'd',
			'É': 'e',
			'Ə': 'e',
			'Ǝ': 'e',
			'ǝ': 'e',
			'é': 'e',
			'È': 'e',
			'è': 'e',
			'Ĕ': 'e',
			'ĕ': 'e',
			'Ê': 'e',
			'ê': 'e',
			'Ế': 'e',
			'ế': 'e',
			'Ề': 'e',
			'ề': 'e',
			'Ễ': 'e',
			'ễ': 'e',
			'Ể': 'e',
			'ể': 'e',
			'Ě': 'e',
			'ě': 'e',
			'Ë': 'e',
			'ë': 'e',
			'Ẽ': 'e',
			'ẽ': 'e',
			'Ė': 'e',
			'ė': 'e',
			'Ȩ': 'e',
			'ȩ': 'e',
			'Ḝ': 'e',
			'ḝ': 'e',
			'Ę': 'e',
			'ę': 'e',
			'Ē': 'e',
			'ē': 'e',
			'Ḗ': 'e',
			'ḗ': 'e',
			'Ḕ': 'e',
			'ḕ': 'e',
			'Ẻ': 'e',
			'ẻ': 'e',
			'Ȅ': 'e',
			'ȅ': 'e',
			'Ȇ': 'e',
			'ȇ': 'e',
			'Ẹ': 'e',
			'ẹ': 'e',
			'Ệ': 'e',
			'ệ': 'e',
			'Ḙ': 'e',
			'ḙ': 'e',
			'Ḛ': 'e',
			'ḛ': 'e',
			'Ɇ': 'e',
			'ɇ': 'e',
			'ɚ': 'e',
			'ɝ': 'e',
			'Ḟ': 'f',
			'ḟ': 'f',
			'ᵮ': 'f',
			'Ƒ': 'f',
			'ƒ': 'f',
			'Ǵ': 'g',
			'ǵ': 'g',
			'Ğ': 'g',
			'ğ': 'g',
			'Ĝ': 'g',
			'ĝ': 'g',
			'Ǧ': 'g',
			'ǧ': 'g',
			'Ġ': 'g',
			'ġ': 'g',
			'Ģ': 'g',
			'ģ': 'g',
			'Ḡ': 'g',
			'ḡ': 'g',
			'Ǥ': 'g',
			'ǥ': 'g',
			'Ɠ': 'g',
			'ɠ': 'g',
			'Ĥ': 'h',
			'ĥ': 'h',
			'Ȟ': 'h',
			'ȟ': 'h',
			'Ḧ': 'h',
			'ḧ': 'h',
			'Ḣ': 'h',
			'ḣ': 'h',
			'Ḩ': 'h',
			'ḩ': 'h',
			'Ḥ': 'h',
			'ḥ': 'h',
			'Ḫ': 'h',
			'ḫ': 'h',
			'H': 'h',
			'̱': 'h',
			'ẖ': 'h',
			'Ħ': 'h',
			'ħ': 'h',
			'Ⱨ': 'h',
			'ⱨ': 'h',
			'Í': 'i',
			'í': 'i',
			'Ì': 'i',
			'ì': 'i',
			'Ĭ': 'i',
			'ĭ': 'i',
			'Î': 'i',
			'î': 'i',
			'Ǐ': 'i',
			'ǐ': 'i',
			'Ï': 'i',
			'ï': 'i',
			'Ḯ': 'i',
			'ḯ': 'i',
			'Ĩ': 'i',
			'ĩ': 'i',
			'İ': 'i',
			'i': 'i',
			'Į': 'i',
			'į': 'i',
			'Ī': 'i',
			'ī': 'i',
			'Ỉ': 'i',
			'ỉ': 'i',
			'Ȉ': 'i',
			'ȉ': 'i',
			'Ȋ': 'i',
			'ȋ': 'i',
			'Ị': 'i',
			'ị': 'i',
			'Ḭ': 'i',
			'ḭ': 'i',
			'I': 'i',
			'ı': 'i',
			'Ɨ': 'i',
			'ɨ': 'i',
			'Ĵ': 'j',
			'ĵ': 'j',
			'J': 'j',
			'̌': 'j',
			'ǰ': 'j',
			'ȷ': 'j',
			'Ɉ': 'j',
			'ɉ': 'j',
			'ʝ': 'j',
			'ɟ': 'j',
			'ʄ': 'j',
			'Ḱ': 'k',
			'ḱ': 'k',
			'Ǩ': 'k',
			'ǩ': 'k',
			'Ķ': 'k',
			'ķ': 'k',
			'Ḳ': 'k',
			'ḳ': 'k',
			'Ḵ': 'k',
			'ḵ': 'k',
			'Ƙ': 'k',
			'ƙ': 'k',
			'Ⱪ': 'k',
			'ⱪ': 'k',
			'Ĺ': 'a',
			'ĺ': 'l',
			'Ľ': 'l',
			'ľ': 'l',
			'Ļ': 'l',
			'ļ': 'l',
			'Ḷ': 'l',
			'ḷ': 'l',
			'Ḹ': 'l',
			'ḹ': 'l',
			'Ḽ': 'l',
			'ḽ': 'l',
			'Ḻ': 'l',
			'ḻ': 'l',
			'Ł': 'l',
			'ł': 'l',
			'̣': 'l',
			'Ŀ': 'l',
			'ŀ': 'l',
			'Ƚ': 'l',
			'ƚ': 'l',
			'Ⱡ': 'l',
			'ⱡ': 'l',
			'Ɫ': 'l',
			'ɫ': 'l',
			'ɬ': 'l',
			'ɭ': 'l',
			'ȴ': 'l',
			'Ḿ': 'm',
			'ḿ': 'm',
			'Ṁ': 'm',
			'ṁ': 'm',
			'Ṃ': 'm',
			'ṃ': 'm',
			'ɱ': 'm',
			'Ń': 'n',
			'ń': 'n',
			'Ǹ': 'n',
			'ǹ': 'n',
			'Ň': 'n',
			'ň': 'n',
			'Ñ': 'n',
			'ñ': 'n',
			'Ṅ': 'n',
			'ṅ': 'n',
			'Ņ': 'n',
			'ņ': 'n',
			'Ṇ': 'n',
			'ṇ': 'n',
			'Ṋ': 'n',
			'ṋ': 'n',
			'Ṉ': 'n',
			'ṉ': 'n',
			'Ɲ': 'n',
			'ɲ': 'n',
			'Ƞ': 'n',
			'ƞ': 'n',
			'ɳ': 'n',
			'ȵ': 'n',
			'N': 'n',
			'̈': 'n',
			'n': 'n',
			'Ó': 'o',
			'ó': 'o',
			'Ò': 'o',
			'ò': 'o',
			'Ŏ': 'o',
			'ŏ': 'o',
			'Ô': 'o',
			'ô': 'o',
			'Ố': 'o',
			'ố': 'o',
			'Ồ': 'o',
			'ồ': 'o',
			'Ỗ': 'o',
			'ỗ': 'o',
			'Ổ': 'o',
			'ổ': 'o',
			'Ǒ': 'o',
			'ǒ': 'o',
			'Ö': 'o',
			'ö': 'o',
			'Ȫ': 'o',
			'ȫ': 'o',
			'Ő': 'o',
			'ő': 'o',
			'Õ': 'o',
			'õ': 'o',
			'Ṍ': 'o',
			'ṍ': 'o',
			'Ṏ': 'o',
			'ṏ': 'o',
			'Ȭ': 'o',
			'ȭ': 'o',
			'Ȯ': 'o',
			'ȯ': 'o',
			'Ȱ': 'o',
			'ȱ': 'o',
			'Ø': 'o',
			'ø': 'o',
			'Ǿ': 'o',
			'ǿ': 'o',
			'Ǫ': 'o',
			'ǫ': 'o',
			'Ǭ': 'o',
			'ǭ': 'o',
			'Ō': 'o',
			'ō': 'o',
			'Ṓ': 'o',
			'ṓ': 'o',
			'Ṑ': 'o',
			'ṑ': 'o',
			'Ỏ': 'o',
			'ỏ': 'o',
			'Ȍ': 'o',
			'ȍ': 'o',
			'Ȏ': 'o',
			'ȏ': 'o',
			'Ơ': 'o',
			'ơ': 'o',
			'Ớ': 'o',
			'ớ': 'o',
			'Ờ': 'o',
			'ờ': 'o',
			'Ỡ': 'o',
			'ỡ': 'o',
			'Ở': 'o',
			'ở': 'o',
			'Ợ': 'o',
			'ợ': 'o',
			'Ọ': 'o',
			'ọ': 'o',
			'Ộ': 'o',
			'ộ': 'o',
			'Ɵ': 'o',
			'ɵ': 'o',
			'Ṕ': 'p',
			'ṕ': 'p',
			'Ṗ': 'p',
			'ṗ': 'p',
			'Ᵽ': 'p',
			'Ƥ': 'p',
			'ƥ': 'p',
			'P': 'p',
			'̃': 'p',
			'p': 'p',
			'ʠ': 'q',
			'Ɋ': 'q',
			'ɋ': 'q',
			'Ŕ': 'r',
			'ŕ': 'r',
			'Ř': 'r',
			'ř': 'r',
			'Ṙ': 'r',
			'ṙ': 'r',
			'Ŗ': 'r',
			'ŗ': 'r',
			'Ȑ': 'r',
			'ȑ': 'r',
			'Ȓ': 'r',
			'ȓ': 'r',
			'Ṛ': 'r',
			'ṛ': 'r',
			'Ṝ': 'r',
			'ṝ': 'r',
			'Ṟ': 'r',
			'ṟ': 'r',
			'Ɍ': 'r',
			'ɍ': 'r',
			'ᵲ': 'r',
			'ɼ': 'r',
			'Ɽ': 'r',
			'ɽ': 'r',
			'ɾ': 'r',
			'ᵳ': 'r',
			'ß': 's',
			'Ś': 's',
			'ś': 's',
			'Ṥ': 's',
			'ṥ': 's',
			'Ŝ': 's',
			'ŝ': 's',
			'Š': 's',
			'š': 's',
			'Ṧ': 's',
			'ṧ': 's',
			'Ṡ': 's',
			'ṡ': 's',
			'ẛ': 's',
			'Ş': 's',
			'ş': 's',
			'Ṣ': 's',
			'ṣ': 's',
			'Ṩ': 's',
			'ṩ': 's',
			'Ș': 's',
			'ș': 's',
			'ʂ': 's',
			'S': 's',
			'̩': 's',
			's': 's',
			'Þ': 't',
			'þ': 't',
			'Ť': 't',
			'ť': 't',
			'T': 't',
			'ẗ': 't',
			'Ṫ': 't',
			'ṫ': 't',
			'Ţ': 't',
			'ţ': 't',
			'Ṭ': 't',
			'ṭ': 't',
			'Ț': 't',
			'ț': 't',
			'Ṱ': 't',
			'ṱ': 't',
			'Ṯ': 't',
			'ṯ': 't',
			'Ŧ': 't',
			'ŧ': 't',
			'Ⱦ': 't',
			'ⱦ': 't',
			'ᵵ': 't',
			'ƫ': 't',
			'Ƭ': 't',
			'ƭ': 't',
			'Ʈ': 't',
			'ʈ': 't',
			'ȶ': 't',
			'Ú': 'u',
			'ú': 'u',
			'Ù': 'u',
			'ù': 'u',
			'Ŭ': 'u',
			'ŭ': 'u',
			'Û': 'u',
			'û': 'u',
			'Ǔ': 'u',
			'ǔ': 'u',
			'Ů': 'u',
			'ů': 'u',
			'Ü': 'u',
			'ü': 'u',
			'Ǘ': 'u',
			'ǘ': 'u',
			'Ǜ': 'u',
			'ǜ': 'u',
			'Ǚ': 'u',
			'ǚ': 'u',
			'Ǖ': 'u',
			'ǖ': 'u',
			'Ű': 'u',
			'ű': 'u',
			'Ũ': 'u',
			'ũ': 'u',
			'Ṹ': 'u',
			'ṹ': 'u',
			'Ų': 'u',
			'ų': 'u',
			'Ū': 'u',
			'ū': 'u',
			'Ṻ': 'u',
			'ṻ': 'u',
			'Ủ': 'u',
			'ủ': 'u',
			'Ȕ': 'u',
			'ȕ': 'u',
			'Ȗ': 'u',
			'ȗ': 'u',
			'Ư': 'u',
			'ư': 'u',
			'Ứ': 'u',
			'ứ': 'u',
			'Ừ': 'u',
			'ừ': 'u',
			'Ữ': 'u',
			'ữ': 'u',
			'Ử': 'u',
			'ử': 'u',
			'Ự': 'u',
			'ự': 'u',
			'Ụ': 'u',
			'ụ': 'u',
			'Ṳ': 'u',
			'ṳ': 'u',
			'Ṷ': 'u',
			'ṷ': 'u',
			'Ṵ': 'u',
			'ṵ': 'u',
			'Ʉ': 'u',
			'ʉ': 'u',
			'Ṽ': 'v',
			'ṽ': 'v',
			'Ṿ': 'v',
			'ṿ': 'v',
			'Ʋ': 'v',
			'ʋ': 'v',
			'Ẃ': 'w',
			'ẃ': 'w',
			'Ẁ': 'w',
			'ẁ': 'w',
			'Ŵ': 'w',
			'ŵ': 'w',
			'W': 'w',
			'̊': 'w',
			'ẘ': 'w',
			'Ẅ': 'w',
			'ẅ': 'w',
			'Ẇ': 'w',
			'ẇ': 'w',
			'Ẉ': 'w',
			'ẉ': 'w',
			'Ẍ': 'x',
			'ẍ': 'x',
			'Ẋ': 'x',
			'ẋ': 'x',
			'Ý': 'y',
			'ý': 'y',
			'Ỳ': 'y',
			'ỳ': 'y',
			'Ŷ': 'y',
			'ŷ': 'y',
			'Y': 'y',
			'ẙ': 'y',
			'Ÿ': 'y',
			'ÿ': 'y',
			'Ỹ': 'y',
			'ỹ': 'y',
			'Ẏ': 'y',
			'ẏ': 'y',
			'Ȳ': 'y',
			'ȳ': 'y',
			'Ỷ': 'y',
			'ỷ': 'y',
			'Ỵ': 'y',
			'ỵ': 'y',
			'ʏ': 'y',
			'Ɏ': 'y',
			'ɏ': 'y',
			'Ƴ': 'y',
			'ƴ': 'y',
			'Ź': 'z',
			'ź': 'z',
			'Ẑ': 'z',
			'ẑ': 'z',
			'Ž': 'z',
			'ž': 'z',
			'Ż': 'z',
			'ż': 'z',
			'Ẓ': 'z',
			'ẓ': 'z',
			'Ẕ': 'z',
			'ẕ': 'z',
			'Ƶ': 'z',
			'ƶ': 'z',
			'Ȥ': 'z',
			'ȥ': 'z',
			'ʐ': 'z',
			'ʑ': 'z',
			'Ⱬ': 'z',
			'ⱬ': 'z',
			'Ǯ': 'z',
			'ǯ': 'z',
			'ƺ': 'z',
			'２': '2',
			'６': '6',
			'Ｂ': 'B',
			'Ｆ': 'F',
			'Ｊ': 'J',
			'Ｎ': 'N',
			'Ｒ': 'R',
			'Ｖ': 'V',
			'Ｚ': 'Z',
			'ｂ': 'b',
			'ｆ': 'f',
			'ｊ': 'j',
			'ｎ': 'n',
			'ｒ': 'r',
			'ｖ': 'v',
			'ｚ': 'z',
			'１': '1',
			'５': '5',
			'９': '9',
			'Ａ': 'A',
			'Ｅ': 'E',
			'Ｉ': 'I',
			'Ｍ': 'M',
			'Ｑ': 'Q',
			'Ｕ': 'U',
			'Ｙ': 'Y',
			'ａ': 'a',
			'ｅ': 'e',
			'ｉ': 'i',
			'ｍ': 'm',
			'ｑ': 'q',
			'ｕ': 'u',
			'ｙ': 'y',
			'０': '0',
			'４': '4',
			'８': '8',
			'Ｄ': 'D',
			'Ｈ': 'H',
			'Ｌ': 'L',
			'Ｐ': 'P',
			'Ｔ': 'T',
			'Ｘ': 'X',
			'ｄ': 'd',
			'ｈ': 'h',
			'ｌ': 'l',
			'ｐ': 'p',
			'ｔ': 't',
			'ｘ': 'x',
			'３': '3',
			'７': '7',
			'Ｃ': 'C',
			'Ｇ': 'G',
			'Ｋ': 'K',
			'Ｏ': 'O',
			'Ｓ': 'S',
			'Ｗ': 'W',
			'ｃ': 'c',
			'ｇ': 'g',
			'ｋ': 'k',
			'ｏ': 'o',
			'ｓ': 's',
			'ｗ': 'w'
		};
	}
	accent_fold(palabra) {
		if (!palabra) { return ''; }
		let ret = '';
		for (let i = 0; i <= palabra.length; i++) {
			ret += this.getAcentos()[palabra.charAt(i)] || palabra.charAt(i);
		}
		return ret;
	}
	autocompleteRequerido(control: AbstractControl) {
		// https://onthecode.co.uk/force-selection-angular-material-autocomplete/
		const selection: any = control.value
		if (typeof selection === 'string') {
			return { incorrect: true }
		}
		return null;
	}
	getYears() {
		return ['2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034', '2035', '2036', '2037', '2038', '2039', '2040', '2041', '2042', '2043', '2044', '2045', '2046', '2047', '2048', '2049', '2050'];
	}
	getTiposDocumento() {
		return ['Cédula de ciudadanía', 'NIT']
	}
	getDeliveryMethods() {
		return [
			{ value: 'gratis', name: 'Envio gratis', desc: '$0.00 / Delivery in 7 to 14 business Days' },
			{ value: 'standard', name: 'Envio Standard', desc: '$7.99 / Delivery in 5 to 7 business Days' },
			{ value: 'express', name: 'Envio Express', desc: '$29.99 / Delivery in 1 business Days' }
		];
	}
	agruparProductosCiudad(lista, campoAgrupacion) {
		let arregloAgrupado = [];
		lista.forEach(producto => {
			let stockade = producto.stock
			let ciudad = campoAgrupacion(producto)

			if (arregloAgrupado[ciudad]) {
				// si el producto existe en el arreglo de la ciudad
				if (!arregloAgrupado[ciudad][producto.idsap]) {
					arregloAgrupado[ciudad][producto.idsap] = producto
				}
				arregloAgrupado[ciudad][producto.idsap].stock += producto.stock

			} else {
				arregloAgrupado[ciudad] = []
				arregloAgrupado[ciudad][producto.idsap] = producto
				arregloAgrupado[ciudad][producto.idsap].stock += producto.stock
			}
		});
		this.productosCiudad = arregloAgrupado
	}
	sedeBodega(idBodega): Sede {
		let sedes = this.getSedes();
		let sedeBodega
		sedes.subscribe(data => {
			let obj = data
			sedeBodega = obj.find(sede => sede.bodegas.find(bodega => bodega.codigo == idBodega))
		})
		return sedeBodega
	}
	/* public getCoordenadasCiudad(ciudad: string) {
		console.log(ciudad)
		this.http.get('https://geocoder.api.here.com/6.2/geocode.json?app_id=UW2CZjjuIHILTSnanRko&app_code=7gp-7GmQWat6pRYMRdVG4Q&searchtext=' + ciudad).subscribe(res => {
			for (let [llave, valor] of Object.entries(res)) {
				console.log('coordenadas', valor.View[0].Result[0].Location.DisplayPosition)
			}
		}, err => {
			console.log(err)
		})
	} */
	/* notificacion(titulo: string, contenido: string) {
		noti.default.create(titulo, {
			body: contenido,
			icon: '../../assets/images/others/logo-ieR.png',
			onClick: function () {
				this.window.focus();
				this.close();
			}
		});
	} */
	salvarCarrito() {
		localStorage.setItem('carrito', JSON.stringify(this.Data.cartList));
	}
	cargarCarrito() {
		let canasta = JSON.parse(localStorage.getItem('carrito')) ? JSON.parse(localStorage.getItem('carrito')) : [];
		canasta.forEach((producto: Producto) => {
			this.Data.totalCartCount += parseInt(producto.enCarrito.toString(), 0);
			this.Data.totalPrice += (producto.enCarrito * producto.precioecommerce);
		})
		// console.log('CARRITO', JSON.parse(localStorage.getItem('carrito')) ? JSON.parse(localStorage.getItem('carrito')) : []);
	}
	vaciarCarrito() {
		this.Data.totalCartCount = null;
		this.Data.totalPrice = null;
		this.Data.cartList = null;
		localStorage.removeItem('carrito');
	}
	sleep = function (ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
	loadImage(el, fn) {
		var img = new Image()
			, src = el.getAttribute('data-src');
		img.onload = function () {
			if (!!el.parent) {
				el.parent.replaceChild(img, el)
			} else {
				el.src = src;
			}
			let sup = fn ? fn() : null;
		}
		img.src = src;
	}
	mensajeSnackBar(mensaje, duracion: number, tipo = 'success', cerrable = null) {
		this.snackBar.open(mensaje, cerrable, { panelClass: [tipo], verticalPosition: 'top', duration: duracion });
	}
	pixelFunction() {
		(function (f: any, b, e, v, n, t, s) {
			if (f.fbq) { return; } n = f.fbq = function () {
				n.callMethod ?
					n.callMethod.apply(n, arguments) : n.queue.push(arguments)
			}; if (!f._fbq) { f._fbq = n; }
			n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
			t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
		})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
		// (window as any).fbq.disablePushState = true;
		(window as any).fbq('init', '1533479686870526');
		(window as any).fbq('track', 'PageView');
	}
	PopupCenter(url, title, w, h) {
		// Fixes dual-screen position                         Most browsers      Firefox
		var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
		var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

		var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
		var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

		var systemZoom = width / window.screen.availWidth;
		var left = (width - w) / 2 / systemZoom + dualScreenLeft
		var top = (height - h) / 2 / systemZoom + dualScreenTop
		var newWindow = window.open(url, title, 'resizable=no, scrollbars=yes, width=' + w / systemZoom + ', height=' + h / systemZoom + ', top=' + top + ', left=' + left);

		// Puts focus on the newWindow
		if (window.focus) { newWindow.focus() };
	}
	getPuntosVenta() {
		return this.puntosVenta;
	}
	setCorreosNotificacion(correos) {
		// console.log("correos asignados", correos)
		this.correosNotificacion = correos;
	}
	// estado de la transaccion y envio mail del anticipo
	consultarEstadoTransaccion(id) {
		let requestData = {
			"metodo": 'getEstadoTransaccion',
			"idtransaccion": id
		}
		// 
		this.http.post(this.globals.backOpenpay + 'openpay.php', requestData).subscribe((estadoTransaccionData: any) => {
			if (estadoTransaccionData.status == 'ok') {
				if (estadoTransaccionData.data.estado == 'completed') {
					// estadoTransaccionData.destinatarios = estadoTransaccionData.data.destinatarios
					let ciudad = estadoTransaccionData.data.ciudad
					let elementos = this.getPuntosVenta()

					if (!estadoTransaccionData.data.destinatarios) {
						if (this.correosNotificacion.length > 0) {
							estadoTransaccionData.destinatarios = this.correosNotificacion
						} else {
							estadoTransaccionData.destinatarios = ["ojhernandez@iegrupo.co", "ojhernandez.iegrupo@gmail.com", "jahernandez@iegrupo.co"];
						}
					} else {
						estadoTransaccionData.destinatarios = estadoTransaccionData.data.destinatarios
					}
					
					

					if (this.usuarioSAP) {
						estadoTransaccionData.adcn = this.usuarioSAP[0].idsap
					} else {
						this.getUsuarioSAP(estadoTransaccionData.data.cliente.email)
					}

					this.snackBar.open("Pago realizado satisfactoriamente de la transacción con ID : " + id + ", estamos notificando y preparando el pedido", '×', { panelClass: "success", verticalPosition: 'top', duration: 5000 });
					this.http.post(this.globals.backOpenpay + 'correoNotificacionAnticipo.php', estadoTransaccionData).subscribe((respuestaNotificacion: any) => {
						console.log("respuesta notificaciones", respuestaNotificacion);
						return;
					})
					/* this.sleep(5000).then(() => {
						// window.location = window.location.href.replace("?id=" + this.idTransaccion, '');
						this.http.post(this.globals.backOpenpay + 'correoNotificacionAnticipo.php', estadoTransaccionData).subscribe((respuestaNotificacion: any) => {
							console.log("respuesta notificaciones", respuestaNotificacion);
							return;
						})
					}) */

				} else {
					this.snackBar.open("El estado de la transacción es " + estadoTransaccionData.data.estado, '×', { panelClass: "error", verticalPosition: 'top', duration: 5000 });
				}
			}
		})
	}
	consultarEstadoTransaccionWompi(id){
		
	}
	getTransaccion(id): void {
		let requestData = {
			"metodo": 'getEstadoTransaccion',
			"idtransaccion": id
		}
		this.http.post(this.globals.backOpenpay + 'openpay.php', requestData).subscribe((estadoTransaccionData: any) => {
			return estadoTransaccionData
		})
	}
	consultarEstadoTransaccionAnticipo ( idTransaccion, correosANotificar) {
		let requestData = {
			"metodo": 'getEstadoTransaccion',
			"idtransaccion": idTransaccion
		}
		// 
		this.http.post(this.globals.backOpenpay + 'openpay.php', requestData).subscribe((estadoTransaccionData: any) => {
			if (estadoTransaccionData.status == 'ok') {
				if (estadoTransaccionData.data.estado == 'completed') {
					estadoTransaccionData.destinatarios = correosANotificar
					
					if (this.usuarioSAP) {
						estadoTransaccionData.adcn = this.usuarioSAP[0].idsap
					} else {
						this.getUsuarioSAP(estadoTransaccionData.data.cliente.email)
					}

					this.snackBar.open("Pago realizado satisfactoriamente de la transacción con ID : " + idTransaccion + ", estamos notificando y preparando el pedido", '×', { panelClass: "success", verticalPosition: 'top', duration: 5000 });
					
					this.sleep(5000).then(() => {
						
						this.http.post(this.globals.backOpenpay + 'correoNotificacionAnticipo.php', estadoTransaccionData).subscribe((respuestaNotificacion: any) => {
							console.log("respuesta notificaciones", respuestaNotificacion);
							return;
						})
					})

				} else {
					this.snackBar.open("El estado de la transacción es " + estadoTransaccionData.data.estado, '×', { panelClass: "error", verticalPosition: 'top', duration: 5000 });
				}
			}
		})
	}
	getMisCoordenadas (){
		/* console.log("estado del navegador", navigator)

		navigator.permissions.query({ name: 'geolocation' }).then( ergo =>{
			console.log("LE ERGO ", ergo.state)
		}) */

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				if (position) {
					this.lat = +position.coords.latitude.toFixed(8);
					this.lng = +position.coords.longitude.toFixed(8);
					this.misCoordenadas = {
						latitud: +this.lat.toFixed(8),
						longitud: +this.lng.toFixed(8)
					}
					this.ciudadActual = this.closestLocation(this.misCoordenadas, this.puntosVenta);
					this.setCiudad(this.ciudadActual)
					// this.getUltimoValor(this.ciudadActual)
				}
			}, error =>{
				console.error("Error", error.message)
			});
		} else {
			console.error("Geolocation is not supported by this browser.")
			// alert("Geolocation is not supported by this browser.");
		}
	}
	closestLocation(targetLocation, locationData) {
		function vectorDistance(dx, dy) {
			return Math.sqrt(dx * dx + dy * dy);
		}
	
		function locationDistance(location1, location2) {
			var dx = location1.latitud - location2.latitud,
				dy = location1.longitud - location2.longitud;
	
			return vectorDistance(dx, dy);
		}
	
		return locationData.reduce(function(prev, curr) {
			var prevDistance = locationDistance(targetLocation , prev),
				currDistance = locationDistance(targetLocation , curr);
			return (prevDistance < currDistance) ? prev : curr;
		});
	}
	setCiudadActual (objetoCiudad) {
		this.ciudadActual = objetoCiudad
	}
	setCiudadActualConPlanoName(plano_name: string) {
        console.log("X", plano_name)
        if(!plano_name)this.setCiudadActualConPlanoName('panama')
		this.ciudadActual = this.puntosVenta.filter( el => el.plano_name.toLowerCase() === plano_name.toLowerCase())[0]
	}
	getCiudadActual(){
		return this.ciudadActual
	}
	/*verModalCotizaciones(producto) {
		// this.modalCotizaciones.closeAll();
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.height = 'auto';
		dialogConfig.width = '600px';
		dialogConfig.data = {
			producto: producto
		}
		this.dialogRefModalCotizaciones = this.modalCotizaciones.open(ModalCotizacionComponent, dialogConfig);	
		
	}
	cerrarModalCotizaciones() {
		this.dialogRefModalCotizaciones.close()
	}*/
	getFechaFormateada(date: Date){
		let fecha = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
		return fecha
	}
	// https://medium.com/mycoding/export-json-to-csv-file-in-angular-d1b674ec79ed
	convertToCSV(objArray, headerList){
		let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
		let str = ''
		let row = 'S.No,';
		for(let index in headerList){
			row += headerList[index]+ ',';
		}
		row = row.slice(0, -1)
		str += row + '\r\n';
	
		for(let i = 0; i < array.length; i++){
			let line  = (i+1)+''
			for(let index in headerList){
				let head = headerList[index]
				line += ',' + array[i][head]
			}
			str += line + '\r\n';
		}
		return str
	}
	downloadFile = (data, filename = 'data') => {
		let csvData = this.convertToCSV(data, ['url', 'nombre', 'codigo', 'MAN', 'precio'])
		console.log(csvData)
		let blob = new Blob(['\ufeff'+csvData], {'type': 'text/csv;charset=utf-8;'});
		let dwldLink = document.createElement("a");
		let url = URL.createObjectURL(blob);
		let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1
		if(isSafariBrowser){
			dwldLink.setAttribute("target", "_blank")
		}
		dwldLink.setAttribute("href", url)
		dwldLink.setAttribute("download", filename+'.csv')
		dwldLink.style.visibility = 'hidden'
		document.body.appendChild(dwldLink)
		dwldLink.click();
		document.body.removeChild(dwldLink)
	}

	verModalCiudades(){
		// this.hojaAux.closeAll();
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.panelClass = 'registro-modal';
		dialogConfig.height = 'auto';
		dialogConfig.position = {
			top: '32px'
		}
		dialogConfig.data = {}

		const dialogReg = this.hojaAux.open(ModalCiudadesComponent, dialogConfig )/* .afterClosed().subscribe(efe => {

			console.log("dialogReg despues de cerrar ==>", efe)
			this.setCiudad(efe) */
		//})
	}
	
}
