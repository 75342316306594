import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Globals } from '../../globals';

@Component({
  selector: 'app-landing-campanias',
  templateUrl: './landing-campanias.component.html',
  styleUrls: ['./landing-campanias.component.scss']
})
export class LandingCampaniasComponent implements OnInit {
  public galeriaCampanias: any[] = []
  tamGaleria = 0;

  constructor(
		public http: HttpClient,
		public globals: Globals,
		
		
  ) { }

  ngOnInit(): void {
    this.getGaleriaCampanias()
  }

  getGaleriaCampanias(){
		this.http.post(`${this.globals.urlApi}galeria-campanias`, {ciudad: 'nacional'}).subscribe((resData:any[])=>{
			this.galeriaCampanias = resData;
			this.tamGaleria = this.galeriaCampanias.length;
		})
	}

}
