/* import { WINDOW } from '@ng-toolkit/universal'; */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TransaccionService } from './transaccion.service';
import { IeAuthService } from './ieauth.service';
import { Observable } from 'rxjs';
import { PedidosService } from './pedidos.service';

@Injectable({
	providedIn: 'root'
})
export class EcollectService {
	private entityCode = 10478;
	respuestaConsultarTransaccion;
	cliente
	constructor(
		private http: HttpClient,
		private globals: Globals,
		private snackBar: MatSnackBar,
		private transaccionService: TransaccionService,
		private ieAuthService: IeAuthService,
		private pedidoService: PedidosService
	) {}
	consultarTransaccion (transaccion: string): Observable<any> {
		let id_transaccion = transaccion
		let cuerpo = {
			EntityCode : this.entityCode,
			TicketId: id_transaccion
		}
		return this.http.post(this.globals.backEcollect + 'consultar_transaccion.php', {cuerpo});
	}
	consultarTransacciones (transacciones: string[]) {
		let cuerpo = {
			EntityCode: this.entityCode,
			transacciones : transacciones,
			url : this.globals.urlApi,
		}
		return this.http.post(this.globals.backEcollect + 'consultar_transacciones.php', {cuerpo})
	}

	verificarCambioEstadoTransaccion() {
		console.log('verificarCambioEstadoTransaccion');
	}
	generarRef(longitud: number) {
		let text = '';
		var posibles = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+';
		for (let i = 0; i < longitud ; i++) {
			text += posibles.charAt(Math.floor(Math.random() * posibles.length ))
		}
		return text;
	}
	pagar(valor_pago, numero_documento, correo, telefono, direccion, nombre_completo, tipo_documento) {
		
		let requestData = {
			valor_pago: valor_pago,
			numero_documento: numero_documento,
			tipo_documento: tipo_documento,
			correo: correo,
			telefono: telefono,
			direccion: direccion,
			nombre_completo: nombre_completo,
			redireccion : window.location.origin + window.location.pathname + '#/cuenta/historial'
		}
		this.http.post(this.globals.backEcollect + 'crear_transaccion.php', {requestData}).subscribe((res: any) => {
			
			if (res.status == 'ok') {
				res.data.createTransactionPaymentResult.cuenta = this.ieAuthService.getCurrentUser().id;
				this.transaccionService.registrarTransaccion(res.data.createTransactionPaymentResult).subscribe(respuestaRegistroTransaccion => {
					
					if (respuestaRegistroTransaccion.ReturnCode == 'SUCCESS') {
						this.pedidoService.pedidoCreado.transaccion = respuestaRegistroTransaccion.TicketId;
						this.pedidoService.updatePedido().subscribe(resPed => {
							console.log('respuesta al actualizar el pedido', resPed);
							
							window.open(res.data.createTransactionPaymentResult.eCollectUrl, '_self');
						})
					} else {
						
						this.snackBar.open(respuestaRegistroTransaccion.ReturnCode, 'RESPUESTA ERROR', {duration: 1000});
					}
				}, err => {
					
					console.log('error al registrar la transaccion', err);
				})
			}
		}, err => {
			
			this.snackBar.open(err, 'RESPUESTA ERROR', {duration: 5000});
		})
	}
	pagarCotizacion (valor_pago, numero_documento, correo, telefono, direccion, nombre_completo, tipo_documento, cotizacion) {
		console.log('cotizacion a pagar', cotizacion)
		
		let requestData = {
			valor_pago: valor_pago,
			numero_documento: numero_documento,
			tipo_documento: tipo_documento,
			correo: correo,
			telefono: telefono,
			direccion: direccion,
			nombre_completo: nombre_completo,
			redireccion : window.location.origin + window.location.pathname + '#/cuenta/historial'
		}
		console.log('rqd', requestData)
		this.http.post(this.globals.backEcollect + 'pagar_cotizacion.php', requestData).subscribe( (respuesta: any) => {
			if (respuesta && respuesta.status === 'ok') {
				respuesta.data.createTransactionPaymentResult.cuenta = this.ieAuthService.getCurrentUser().id;

				this.transaccionService.registrarTransaccion(respuesta.data.createTransactionPaymentResult).subscribe(respuestaRegistroTransaccion => {
					
					if (respuestaRegistroTransaccion.ReturnCode == 'SUCCESS') {
						cotizacion.estado = respuestaRegistroTransaccion.TicketId; // 'transaccion';
						this.http.patch(this.globals.urlApi + 'cotizaciones', cotizacion).subscribe(res => {
							console.log('actualizacion cotizacion', res)
							window.open(respuesta.data.createTransactionPaymentResult.eCollectUrl, '_self');
						})
					} else {
						
						this.snackBar.open(respuestaRegistroTransaccion.ReturnCode, 'RESPUESTA ERROR', {duration: 5000});
					}
				}, err => {
					
					console.log('error al registrar la transaccion', err);
				})
			}
		})
	}
}
