import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-medios-pago',
	templateUrl: './medios-pago.component.html',
	styleUrls: ['./medios-pago.component.scss'],
})
export class MediosPagoComponent implements OnInit {
	mercadoAbierto = false;
	bancolombiaAbierto = false;
	bbvaAbierto = false;
	banagrarioAbierto = false;
	iecommerceAbierto = false;
	daviviendaAbierto = false;

	constructor() { }

	ngOnInit() {
		window.scrollTo(0, 0);
	}

	mostrarMercado(){
		this.mercadoAbierto = !this.mercadoAbierto;
		this.bancolombiaAbierto = false;
		this.bbvaAbierto = false;
		this.banagrarioAbierto = false;
		this.iecommerceAbierto = false;
		this.daviviendaAbierto = false;
	}
	mostrarBancolombia() {
		this.bancolombiaAbierto = !this.bancolombiaAbierto;
		this.mercadoAbierto = false;
		this.bbvaAbierto = false;
		this.banagrarioAbierto = false;
		this.iecommerceAbierto = false;
		this.daviviendaAbierto = false;
	}
	mostrarBbva(){
		this.bbvaAbierto = !this.bbvaAbierto;
		this.mercadoAbierto = false;
		this.bancolombiaAbierto = false;
		this.banagrarioAbierto = false;
		this.iecommerceAbierto = false;
		this.daviviendaAbierto = false;
	}
	mostrarAgrario(){
		this.banagrarioAbierto = !this.banagrarioAbierto;
		this.mercadoAbierto = false;
		this.bancolombiaAbierto = false;
		this.bbvaAbierto = false;
		this.iecommerceAbierto = false;
		this.daviviendaAbierto = false;
	}
	mostrarEcommmerce(){
		this.mercadoAbierto = false;
		this.bancolombiaAbierto = false;
		this.bbvaAbierto = false;
		this.banagrarioAbierto = false;
		this.iecommerceAbierto = !this.iecommerceAbierto;
		this.daviviendaAbierto = false;
	}
	mostrarDavivienda () {
		this.mercadoAbierto = false;
		this.bancolombiaAbierto = false;
		this.bbvaAbierto = false;
		this.banagrarioAbierto = false;
		this.iecommerceAbierto = false;
		this.daviviendaAbierto = !this.daviviendaAbierto;
	}

}
