import { Component, OnInit, HostListener, Inject } from '@angular/core';
/* import { WINDOW } from '@ng-toolkit/universal'; */
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../globals';
// import {S3, Config} from 'aws-sdk';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-mercadosconamor',
	templateUrl: './mercadosconamor.component.html',
	styleUrls: ['./mercadosconamor.component.scss']
})
export class MercadosconamorComponent implements OnInit {
	mercados_entregados: number = 0;
	// https://www.npmjs.com/package/ng-image-slider
	arreglo_imagenes: Array<object> = [];

	// https://stackoverflow.com/questions/21939010/how-can-i-avoid-networking-error-network-failure-when-downloading-a-file-from
	/* public configaws = new  Config({
		accessKeyId: 'AKIAYH47G72QYGFGOTL6',
		secretAccessKey: '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',
		region: 'us-east-1',
		apiVersion: '2006-03-01'
	}) */
	// public contenedor: S3 = new S3(this.configaws);

	public getParams = {
		Bucket: 'mercadosie',
	}
	public imageSize = {
		width: "320",
		height: "320",
		space: 4
	}

	constructor(
		/* @Inject(WINDOW) private window: Window, */
		private http: HttpClient,
		private globals: Globals,
		private _sanitizer: DomSanitizer

	) {
		
	}
	@HostListener('window:resize')
	public onWindowResize(): void {
		(window.innerWidth < 960) ? this.imageSize = {width: "100%", height: "320px", space:4} : this.imageSize = {width: "25%", height: "320px", space:4};
		
	}

	ngOnInit(){
		if (window.innerWidth < 960) {
			this.imageSize.width="100%";
			this.imageSize.height="320px";
			
		} else {
			this.imageSize.width="25%";
			this.imageSize.height="320px";
		}
		/* this.contenedor.listObjects(this.getParams, (err, data) =>{
			if (err) {
				console.log("Ocurrió un error", err);
			} else {
				data.Contents?.forEach(imagen =>{
					this.arreglo_imagenes.push({
						image: "https://mercadosie.s3.amazonaws.com/" + imagen.Key,
						thumbImage: "https://mercadosie.s3.amazonaws.com/" + imagen.Key,
						alt: "",
						title:""
					})
				})
			}
		}) */
		this.http.get(this.globals.urlApi + 'mercados-entregados').subscribe((data: any) => {
			this.mercados_entregados = data.entregados
		})
	}

	getVideoIframe () {
		/* var video, results;
		 if (url === null){
			 return '';
		 }
		 results = url.match('[\\?&]v=([^&#]*)');
		 video = (results === null) ? url : results[1]; */
		 return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/NryaomLzPYQ');
		 // return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/watch?v=NryaomLzPYQ&feature=youtu.be&ab_channel=ieGrupo');
	}

}
