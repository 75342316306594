import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Articulo } from '../../models/articulo';
import { Globals } from '../../globals';
import { Router } from '@angular/router';
import { S3, Config} from 'aws-sdk';

import SwiperCore, { SwiperOptions, Autoplay, Pagination, Navigation } from 'swiper';
SwiperCore.use([Navigation, Autoplay, Pagination]);

@Component({
	selector: 'app-blog',
	templateUrl: './blog.component.html',
	styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
	articulos: Articulo[];
	articuloppal: Articulo;
	imgplaceholder = 'https://via.placeholder.com/1250x500.png?text=preview'

	private imagenespublicidad: any = []
	config: SwiperOptions = {
		observer: true,
		direction: 'horizontal',
		slidesPerView: 1,
		spaceBetween: 0,
		keyboard: true,
		navigation: false,
		pagination: false,
		grabCursor: false,
		loop: true,
		preloadImages: true,
		lazy: true,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false
		},
		speed: 500,
		effect: "coverflow",
		breakpoints: {
			320: {
				slidesPerView: 1
			},
			480: {
				slidesPerView: 1
			},
			740: {
				slidesPerView: 1,
			},
			960: {
				slidesPerView: 1,
			},
			1280: {
				slidesPerView: 1,
			},
			1500: {
				slidesPerView: 1,
			}
		}
	}

	constructor(
		private http: HttpClient,
		private globals: Globals,
		private router: Router
	) {
		this.cargarArticulos();
	}
	ngOnInit() {
		this.cargarPublicidad( this.imagenespublicidad )
	}
	onLoad() {

	}
	cargarArticulos () {
		this.http.get(this.globals.urlApi + 'articulos').subscribe( (res: Articulo[]) => {
			if ( res ) {
				this.articulos = res
				this.articuloppal = this.articulos.shift()
				// this.articulos.length = 3
				this.articulos.forEach(articulo => {
					articulo.contenido = articulo.contenido.replace(/<[^>]*>?/g, '')// .substring(0, 255)
				})
			}
		});
	}
	navegar (url: string) {
		this.router.navigate(['articulo/' + url ])
	}
	cargarPublicidad ( arreglo ) {
		this.imagenespublicidad.push({
			img: 'https://publicidadblog.s3.amazonaws.com/https%3A--www.iegrupo.co--%23-productos-1-12-producto-136103102001.jpg',
			link: ''
		});
		this.imagenespublicidad.push({
			img: 'https://publicidadblog.s3.amazonaws.com/https%3A--www.iegrupo.co-%23-productos-1-12-producto-106103101009.jpg',
			link: ''
		});
		this.imagenespublicidad.push({
			img: 'https://publicidadblog.s3.amazonaws.com/https%3A--www.iegrupo.co-%23-productos-1-12-producto-144102104019.jpg',
			link: ''
		});

		/* let s3 = new S3({params: {Bucket: 'publicidadblog'}});
		let configaws = new  Config({
			accessKeyId: 'AKIAYH47G72QYGFGOTL6',
			secretAccessKey: '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',
			region: 'us-east-1',
			apiVersion: '2006-03-01'
		})
		s3.config = configaws
		let params =  {
			Bucket: 'publicidadblog',
		}
		s3.listObjects(params, function( err, data)  {
			if (err) {
				console.log('le error', err)
			} else {
				data['Contents'].forEach(element => {
					let hiper = element.Key.replace(/-/g, '/').slice(0, element.Key.length - 4)
					let imagen = 'https://publicidadblog.s3.amazonaws.com/' + element.Key.replace(/:/g, '%3A').replace(/#/g, '%23')

					arreglo.push({
						img: imagen,
						link: hiper
					})
				});
			}
		}) */
	}
}
