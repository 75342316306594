import { Component, OnInit, Input } from '@angular/core';
import { SidenavMenuService } from './sidenav-menu.service';
import { BuscadorService } from '../../../services/buscador.service';
import { Router } from '@angular/router';
import { Category } from '../../../app.models';
import { AppService } from '../../../app.service';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../../globals';

@Component({
	selector: 'app-sidenav-menu',
	templateUrl: './sidenav-menu.component.html',
	styleUrls: ['./sidenav-menu.component.scss'],
	providers: [SidenavMenuService]
})
export class SidenavMenuComponent implements OnInit {
	@Input('menuItems') menuItems;
	@Input('menuParentId') menuParentId;
	parentMenu: Array<any>;
	public category: Category;
	marcas: any = [];
	categories: any;
	

	constructor(
		private sidenavMenuService: SidenavMenuService,
		private buscador: BuscadorService,
		public router: Router,
		private appService: AppService,
		private http: HttpClient,
		private globals: Globals
	) {
	}

	ngOnInit() {
		this.parentMenu = this.menuItems.filter(item => item.parentId === this.menuParentId);
	}
	onClick(menuId) {
		this.sidenavMenuService.toggleMenuItem(menuId);
		this.sidenavMenuService.closeOtherSubMenus(this.menuItems, menuId);
	}
	public actualizarBuscadorCategoria(idcategoria: number) {
		this.buscador.objetoBuscadorProductos.marca = null
		this.buscador.productosPorCategoria(idcategoria);
		this.router.navigateByUrl('/chat', {skipLocationChange: true}).then((paso) => {
			if(paso){
				this.router.navigate(['/productos/1/12'])
			}
		});
	}
	public getCategories() {
		this.appService.getCategories().subscribe( async data => {
			this.categories = data;
			this.category = data[0];
			this.appService.Data.categories = data;
			let estructCategorias: any = []
			this.categories.forEach(item => {
				if (item.id != 0 && item.hasSubCategory && item.parentId == 0) {
					estructCategorias.push(item)
				}
			})

			await estructCategorias.forEach(cateppal => {
				if (this.estructurarCategorias(cateppal).length > 0) {
					let temp = []
					temp = this.estructurarCategorias(cateppal)
					cateppal.subs = temp
					cateppal.subs.forEach(catesec => {
						if (this.estructurarCategorias(catesec).length > 0) {
							let temp = []
							temp = this.estructurarCategorias(catesec)
							catesec.subs = temp
							catesec.subs.forEach(cateter => {
								if (this.estructurarCategorias(cateter).length > 0) {
									let temp = []
									temp = this.estructurarCategorias(cateter)
									cateter.subs = temp
									cateter.subs.forEach(catecua => {
										if (this.estructurarCategorias(catecua).length > 0) {
											let temp = []
											temp = this.estructurarCategorias(catecua)
											catecua.subs = temp
										}
									})
								}
							})
						}
					})
				}
			});
			this.appService.Data.categories = estructCategorias
			this.categories = estructCategorias
		});
	}
	public estructurarCategorias (categoria: Category) {
		return this.categories.filter(category => category.parentId == categoria.id )
	}
	public actualizarBuscadorMarca(idmarca: number) {
		this.buscador.objetoBuscadorProductos.categoria = null
		this.buscador.productosPorMarca(idmarca);
		this.router.navigateByUrl('/chat', {skipLocationChange: true}).then((paso) => {
			if(paso){
				this.router.navigate(['/productos/1/12'])
			}
		});
	}
	mostrarChat() {
		this.appService.PopupCenter('https://iegrupo.bitrix24.com/online/chat?user_lang=la', 'ie chat', 400, 750)
	}
	getMarcas() {
		this.http.get(this.globals.urlApi+"marcas/activas").subscribe((marcas:any) => {
			this.marcas = marcas
			this.marcas.sort(this.ordenamientoDinamico("nombre","asc"))
		})
	}
	ordenamientoDinamico(propiedad, orden) {
		let ordenar = 1;
		if(orden === "desc") {
			ordenar = -1;
		}
		return function (a, b){
			if(a[propiedad] < b[propiedad]){
				return -1 * ordenar
			} else if (a[propiedad] > b[propiedad]){
				return 1 * ordenar
			} else {
				return 0 * ordenar
			}
		}
	}
	navegarA(marca) {
		//this.router.navigate(['/marcas/' + marca ]);
		this.router.navigateByUrl('/chat', { skipLocationChange: true }).then((paso) => {
			if(paso){
				this.router.navigate(['/marcas/' + marca ]);
			}
		});
	}

}
