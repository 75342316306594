import { Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-quienes-somos',
	templateUrl: './quienes-somos.component.html',
	styleUrls: ['./quienes-somos.component.scss']
})
export class QuienesSomosComponent implements OnInit {
	imagenes = {
		mision: 'assets/images/others/img_mision.jpg',
	};
	constructor() { }

	ngOnInit() {
	}
}
