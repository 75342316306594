import { Injectable } from '@angular/core';


@Injectable({
	providedIn: 'root'
})
export class OpenpayService {
	
	constructor(
    ) {}

    pagar(nombre, apellido, correo, telefono, departamento, ciudad, direccion, precio){
        let requestData = {
            metodo: "pagoPSE",
            nombre: nombre,
            apellido: apellido,
            correo: correo,
            telefono: telefono,
            departamento: departamento,
            ciudad: ciudad,
            direccion: direccion,
            precio: precio
        }
        /*this.http.post(this.globals.backOpenpay + 'openpay', {requestData}).subscribe((res: any) => {
            // this.router
        }*/
    }
}