import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Globals } from '../../globals';
import { BuscadorService } from '../../services/buscador.service';
import { Observable, of } from 'rxjs';
import { AppService } from '../../app.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-buscador',
	templateUrl: './buscador.component.html',
	styleUrls: ['./buscador.component.scss']
})
export class BuscadorComponent implements OnInit {
	buscador: UntypedFormControl;
	opciones: any[] = [];
	filteredOptions: Observable<any[]>;
	largocadena: number = 0;
	cargandoProductosBuscador: boolean = false;

	laciudad

	constructor(
		private http: HttpClient,
		private globals: Globals,
		private buscadorService: BuscadorService,
		private appService: AppService,
		private router: Router,
	) { 
		this.laciudad = this.appService.getCiudadActual()
	}

	ngOnInit(): void {
		let cadenaTemp = ''
		this.buscador = new UntypedFormControl();
		this.buscador.valueChanges.subscribe( async cadenaBuscada => {
			let cadenaaux = this.buscador.value
			await setTimeout( () => {
				this.cargandoProductosBuscador = true;
				this.filteredOptions = null;
				this.largocadena = cadenaBuscada ? cadenaBuscada.length : 0;
				if (this.largocadena > 2 && cadenaaux == cadenaTemp) {
					this.buscarCadena(cadenaBuscada.replace('/', '%2F')).subscribe((productsFound: any[]) => {
						productsFound.forEach(producto => {
							producto['show'] = false
							producto['url'] = producto.url?.split(' ').join('-');
						})
						this.filteredOptions = of(productsFound)
						/* this.filteredOptions.forEach(productoLista => {
							productoLista.forEach(item => {
								productoLista['url'] = item.nombre.replace('/', '%2F')
							})
						}) */

						this.cargandoProductosBuscador = false;
					});
				} else {
					this.cargandoProductosBuscador = false;
				}
			}, 500);
			cadenaTemp = cadenaBuscada
		});
	}

	ejecutarBusqueda() {
		let cadenaBuscada = this.buscador.value
		this.largocadena = cadenaBuscada.length
		if (cadenaBuscada.length > 2) {
			this.filteredOptions = null;
			this.largocadena = 0;
			this.buscarCadena(cadenaBuscada).subscribe((productsFound: any[]) => {
				productsFound.forEach(prod => {
					prod['show'] = false
				})
				this.filteredOptions = of(productsFound)
			});
		}
	}
	buscarCadena(cadena: string) {
		const url = `${this.globals.urlApi}v2/productos-buscador/${this.laciudad.plano_name}/${cadena}`; //TODO:activar v2
		/* const url = `${this.globals.urlApi}productos-buscador/${cadena}`; */
		return this.http.get(url);
	}
	verProductosResultados() {
		this.filteredOptions.subscribe((res: any) => {
			this.opciones = res;
			this.buscadorService.resultadosBuscador = true;
			this.buscadorService.opcionesResultados = this.opciones;
			
			if(this.router.url.includes('resultados')){
				this.router.navigateByUrl('/blog').then( algo => {
					if(algo){
						this.router.navigate(['/productos/1/12/resultados']);		
					}
				})
				
			} else {
				this.router.navigate(['/productos/1/12/resultados']);
			}
			this.filteredOptions = null;
			this.largocadena = 0;
			this.buscador.setValue('');
			this.cargandoProductosBuscador = false;
		})
	}
	verTodosEnter(event) {
		if (this.largocadena > 2 && (event.keyCode === 13 || event.key == 'Enter')) {
			this.verProductosResultados();
		}
	}

}
