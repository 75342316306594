import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Globals } from './../../globals';
import { emailValidator } from '../../theme/utils/app-validators';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { AppService } from '../../app.service';
import { IeAuthService } from '../../services/ieauth.service';
import * as XLSX from 'xlsx';


export interface PeriodicElement {
	id: string;
	correo_comprador: string;
	correo_vendedor: string;
	enviado: boolean;
	metodo_pago: string;
	url: string;
  }

/* agrega venta*/
export interface DialogDataVentaBingo {
	info: any;
}
@Component({
	selector: 'app-modal-venta-bingo',
	templateUrl: 'hojaVenta.html',
	
})
export class ModalVentaBingoComponent {
	formularioComprador: UntypedFormGroup
	comprador

	total_cartones:number = 1200;
	cartones_no_disponibles: number[] = [];

	constructor(
		public dialogRef: MatDialogRef<ModalVentaBingoComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private _formBuilder: UntypedFormBuilder,
		private https: HttpClient,
		private globals: Globals
	){
		console.log("dataVenta", data)
		this.formularioComprador = this._formBuilder.group({
			nombre: ['', Validators.required],
			identificacion: ['', Validators.required],
			celular: ['', Validators.required],
			n_cartones: ['', Validators.required],
			metodo_pago: [null, Validators.required],
			email: ['', Validators.compose([Validators.required, emailValidator])],
		})
	}
	ngOnInit() {		
	}
	cerrarModal() {
		this.dialogRef.close();
	}
	seleccionarMP(seleccion){
		this.formularioComprador.controls['metodo_pago'].setValue(seleccion)
		console.log ("seleccion =>", seleccion)
	}
	generarNumeroCarton(arreglo_no_disponibles: number[]): number {
		let numero_carton
		do{
			numero_carton = Math.floor(Math.random() * this.total_cartones)
		}while(arreglo_no_disponibles.includes(numero_carton))
		
		return numero_carton;
	}
	crearCarton(numero_bingo: number, correo_comprador: string, correo_vendedor: string, metodo_pago: string){
		return this.https.post(`${this.globals.urlApi}bingo-cartones/crear`, {
			id: numero_bingo,
			correo_comprador: correo_comprador,
			correo_vendedor: correo_vendedor,
			metodo_pago: metodo_pago,
			url : `https://ie-cartones-bingo.s3.amazonaws.com/ie+fundacio%CC%81n+carton+${numero_bingo}.pdf`,
			enviado: false
		})
	}
	accionBoton(event) {
		event.preventDefault();
		console.log("##### ===>", event)
	}
	guardarComprador( formulario, event ){
		//event.preventDefault()
		if(this.formularioComprador.status === 'VALID'){
			this.https.get(`${this.globals.urlApi}bingo-compradores/${formulario.email}`).subscribe( (resData:any) =>{
				if(resData){
					this.comprador = resData
					
					console.log("existe el comprador, asignar numeros de cartones")
					this.https.get(`${this.globals.urlApi}bingo-cartones`).subscribe( (cartones_ya_asignados: number[]) => {
						this.cartones_no_disponibles = cartones_ya_asignados.map( (el:any) => parseInt(el.id))
						// console.log("cartones asignados", this.cartones_no_disponibles )
						let temp_cartones: number[] = [];
						for(let i = 0; i < formulario.n_cartones; i++){
							let numero_carton:number = this.generarNumeroCarton(this.cartones_no_disponibles)
							temp_cartones.push(numero_carton)
							this.cartones_no_disponibles.push(numero_carton)
						}
						let llamadosApi = [] 
						temp_cartones.forEach(element => { // crear registro de los cartones - venta
							llamadosApi.push(
								this.crearCarton(element, this.comprador.email, this.data.vendedor.email, formulario.metodo_pago)
							)
						});
						// despues ejecutar los llamados
						forkJoin(llamadosApi).subscribe( results => {
							// console.log("resultados del join", results)
						})
					});
				} else {
					this.https.post(`${this.globals.urlApi}bingo-compradores/crear`, formulario).subscribe( (comprador: any) =>{
						this.comprador = comprador
						this.https.get(`${this.globals.urlApi}bingo-cartones`).subscribe( (cartones_ya_asignados: number[]) => {
							this.cartones_no_disponibles = cartones_ya_asignados.map( (el:any) => parseInt(el.id))
							console.log("cartones asignados", this.cartones_no_disponibles.length )
							let temp_cartones = []
							for(let i = 0; i < formulario.n_cartones; i++){
								let numero_carton = this.generarNumeroCarton(this.cartones_no_disponibles)
								temp_cartones.push(numero_carton)
								this.cartones_no_disponibles.push(numero_carton)
							}
							let llamadosApi = [] 
							temp_cartones.forEach(element => { // crear registro de los cartones - venta
								llamadosApi.push(
									this.crearCarton(element, this.comprador.email, this.data.vendedor.email, formulario.metodo_pago)
								)
							});
							// despues ejecutar los llamados
							forkJoin(llamadosApi).subscribe( results => {
								/* console.log("resultados del join", results) */
							})
						});
					});
				}
				
			})
			this.data.info = formulario
		}
	}
}
/* agrega venta FIN */

/* Agregar comprador */
@Component({
	selector: 'app-modal-comprador-bingo',
	templateUrl: './modalComprador.html'
})
export class ModalCompradorBingo {
	formularioComprador: UntypedFormGroup
	constructor(
		public dialogRef: MatDialogRef<ModalCompradorBingo>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private _formBuilder: UntypedFormBuilder,
		private https: HttpClient,
		private globals: Globals,
		private appService: AppService
	){
		this.formularioComprador = this._formBuilder.group({
			nombre: ['', Validators.required],
			identificacion: ['', Validators.required],
			celular: ['', Validators.required],
			email: ['', Validators.compose([Validators.required, emailValidator])],
		})
	}
	ngOnInit(): void {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		/* console.log("que llegó ??", this.data) */
	}
	cerrarModal() {
		this.dialogRef.close();
	}
	guardarComprador(formulario, evento){
		evento.preventDefault()
		console.log("formulario", formulario)
		this.data.registro = formulario
		this.dialogRef.close(this.data);
		console.log("la data ", this.data)

		this.https.get(`${this.globals.urlApi}bingo-compradores/${formulario.email}`).subscribe( (resData:any) =>{
			// console.log("buscando comprador... ", resData)
			if(resData){
				this.https.post(`${this.globals.urlApi}bingo-cartones/actualizar`, {
					id: this.data.datos.id,
					correo_comprador: resData.email
				}).subscribe( (respuestaUpdate:any) =>{
					console.log("que estoy enviando ??", this.data)
					this.dialogRef.close(this.data);
				})
			} else {
				this.https.post(`${this.globals.urlApi}bingo-compradores/crear`, formulario).subscribe( (comprador: any) =>{
					this.https.post(`${this.globals.urlApi}bingo-cartones/actualizar`, {
						id: this.data.datos.id,
						correo_comprador: comprador.email
					}).subscribe( (respuestaUpdate:any) =>{
						this.appService.mensajeSnackBar('Comprador asignado', 3000, 'success', 'x');
						this.dialogRef.close(this.data);
					})	
				})
			}

		})


		/* this.https.post(`${this.globals.urlApi}bingo-compradores/crear`, formulario).subscribe( (resData:any) =>{
			if(resData){
				this.dialogRef.close(resData)
			}
		}) */
	}
}
/* Agregar comprador FIN */

export interface Comprador {
	nombre: string;
	identificacion: number;
	celular: number;
	cartones: number[];
	metodo_pago: string;
	email: string;
}

@Component({
	selector: 'app-gestion-bingo',
	templateUrl: './gestion-bingo.component.html',
	styleUrls: ['./gestion-bingo.component.scss']
})
export class GestionBingoComponent implements OnInit {
	arreglo_asignados: number[] = [];
	numero_generado: number = -1;
	cantidad_cartones: number = 1200;

	vendedor
	vendedorActivo: boolean = false;

	arreglo_email_compradores = [];
	arreglo_compradores = [];
	arreglo_tabla_ventas = [];
	listado_cartones_vendedor = [];

	formularioVendedor: UntypedFormGroup;

	@ViewChild(MatTable) table : MatTable<Comprador>;
	displayedColumns: string[] = ['nombre', 'identificacion', 'celular', 'n_cartones', 'metodo_pago', 'email', 'acciones'];
	dataSource = [...this.arreglo_compradores]
	agregandoComprador: boolean = false
	dataVenta

	ELEMENT_DATA: PeriodicElement[] = [];

	displayedColumns1: string[] = ['id', 'correo_comprador', 'correo_vendedor', 'enviado', 'metodo_pago', 'url'];
  	dataSource1 // = [] // [...this.arreglo_compradores] // = new MatTableDataSource(this.ELEMENT_DATA);

	usuario

  	
	constructor(
		private _formBuilder: UntypedFormBuilder,
		private https: HttpClient,
		private globals: Globals,
		public dialog: MatDialog,
		private appService: AppService,
		private authService: IeAuthService
	) {
		this.formularioVendedor = this._formBuilder.group({
			nombre: ['', Validators.required],
			celular: ['', Validators.required],
			email: ['', Validators.compose([Validators.required, emailValidator])],
			ciudad: ['', Validators.required]
		})
		this.usuario = this.authService.getCurrentUser()
		
	}
	ngOnInit(): void {
		// this.cargarImagenQR('asdas')
	}
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource1.filter = filterValue.trim().toLowerCase();
	}
	crearVendedor(formulario, evento){
		evento.preventDefault()
		let permitidos: string[] = [
			'amtorres@iegrupo.co',
			'jalozano@iegrupo.co',
			'mllizcano@iegrupo.co',
			'npnieto@iegrupo.co',
			'cmsolis@iegrupo.co',
			'yovargas@iegrupo.co',
			'nplozano@iegrupo.co',
			'ykvillanueva@iegrupo.co',
			'vlmarin@iegrupo.co',
			'bromero@iegrupo.co',
			'hjcardona@iegrupo.co',
			'lsalarcon@iegrupo.co',
			'ammarin@iegrupo.co',
			'ehhernandez@iegrupo.co',
			'ojhernandez@iegrupo.co',
			'iefundacion2017@gmail.com',
			'gngrimaldo@iegrupo.co',
			'ieportalweb@iegrupo.co',
			'ojhzmf@gmail.com',
			'jahernandez@iegrupo.co'
		];
		if (permitidos.includes(formulario.email)){
			this.https.get(`${this.globals.urlApi}bingo-vendedores/${formulario.email}`).subscribe( (resData:any) =>{
				if(resData){
					if(resData.registros == 0){ //si no existe el vendedor hay que crearlo
						this.https.post(`${this.globals.urlApi}bingo-vendedores/crear`, formulario).subscribe( (vendedor: any) =>{
							this.vendedor = vendedor
							this.vendedorActivo = true
						}, err =>{
							this.vendedorActivo = false
						});
					} else { // si existe
						this.vendedor = resData
						this.getCartonesVendidos(this.vendedor.email)
						this.vendedorActivo = true
					}
				}
				
			})
		} else {
			this.appService.mensajeSnackBar('Usuario no permitido',3000,'error','x');
		}
	}
	agregarComprador() {
		this.agregandoComprador = true;
		
		const dialogRefVenta = this.dialog.open(ModalVentaBingoComponent, {
			disableClose: true,
			autoFocus: true,
			data: {
				vendedor: this.vendedor,
			}
		});
		dialogRefVenta.afterClosed().subscribe( respuestaData =>{
			this.agregandoComprador = false
			this.getCartonesVendidos(this.vendedor.correo_vendedor);
			this.appService.mensajeSnackBar('Comprador asignado', 3000, 'success', 'x');
		})
	}
	asignarComprador(elemento){
		elemento.id = parseInt(elemento.id)
		/* console.log("elemento de asignar comprador", elemento) */
		this.dialog.open(ModalCompradorBingo,{
			data: {
				datos: elemento
			}
		}).afterClosed().subscribe( value => {
			
			elemento.email_comprador = value.registro.email;
			this.getCartonesVendidos(this.vendedor.email)
			// this.getCartonesVendidos(elemento.correo_vendedor);
			// this.appService.mensajeSnackBar('Comprador asignado', 3000, 'success', 'x');
		});
	}
	compradorAsignado(emailcomprador, emailvendedor): boolean {
		return emailcomprador === emailvendedor
	}
	getCartonesVendidos(correo_vendedor) {
		this.listado_cartones_vendedor = [];
		this.arreglo_compradores = [];

		this.https.get(`${this.globals.urlApi}bingo-cartones-vendedor/${correo_vendedor}`).subscribe( (resData: any[]) => {
			// cartones vendidos resData
			this.listado_cartones_vendedor = resData;
			this.arreglo_compradores =  this.agruparPor(resData)
			
			// agregar info de cada registro del arreglo de compradores
			let llamadosApi = [] 
			this.arreglo_compradores.forEach(element => { // crear registro de los cartones - venta
				llamadosApi.push(
					this.https.get(`${this.globals.urlApi}bingo-compradores/${element.email}`)
					)
				});
				// despues ejecutar los llamados
				forkJoin(llamadosApi).subscribe( (results: any[]) => {
					results.map( (el) => {
						this.arreglo_compradores.map ( ac => {
							if( ac.email === el.email) {
								ac.nombre = el.nombre
								ac.identificacion = el.identificacion
								ac.celular = el.celular
							}
						})
					})
					//renderizar
					this.dataSource1 = new MatTableDataSource(this.listado_cartones_vendedor);
					// this.dataSource = [...this.arreglo_compradores]
			})
		})
	}
	agruparPor(arreglo: any[]){
		let nuevoArregloCompradores: Comprador[] = []
		arreglo.forEach( x => {
			let temp = nuevoArregloCompradores.find( cadauno => cadauno.email === x.correo_comprador)
			if(!temp){
				nuevoArregloCompradores.push({
					nombre: null,
					identificacion: null,
					celular: null,
					cartones: [parseInt(x.id)],
					metodo_pago: x.metodo_pago,
					email: x.correo_comprador
				})
			} else {
				temp.cartones.push(parseInt(x.id));
			}
		})
		return nuevoArregloCompradores;
	}
	notificar(datos){
		// console.log("datos => ", datos)
		this.https.post(`${this.globals.backServiciosECommerce}bingo/correo_venta_bingo.php`,{datos}).subscribe( (resData: any) =>{
			if(resData.status === 'ok'){
				this.https.post(`${this.globals.urlApi}bingo-cartones/actualizar`, {
					id: datos.id,
					enviado: true
				}).subscribe( (respuestaUpdate:any) =>{
					this.appService.mensajeSnackBar('Mensaje enviado', 3000, 'success', 'x');
					console.log(" registro actualizado con correo enviado ", respuestaUpdate);
				})
			}
			// console.log("%%%", resData)
		})
	}
	async cargarImagenQR(cadenaa: string){
		/* https://egghead.io/lessons/javascript-create-an-image-with-javascript-using-fetch-and-url-createobjecturl */
		let cadena = "https://chart.googleapis.com/chart?chs=177x177&cht=qr&chl=https://ie-recursos.s3.amazonaws.com/politicas-SAGRILAFT-y-etica-empresarial.pdf&choe=UTF-8"
		const response = await fetch(cadena);
		const blob = await response.blob();
		const url = URL.createObjectURL(blob);
		const image = new Image();
		image.src = url;
		document.getElementById('asdasd').appendChild(image);
		// https://www.iegrupo.co/servicios/serviciosECommerce/bingo/correo_venta_bingo.php
		console.log(`${this.globals.backServiciosECommerce}bingo/correo_venta_bingo.php`)
		this.https.post(`https://www.iegrupo.co/servicios/serviciosECommerce/bingo/correo_venta_bingo.php`,{ req:cadena}).subscribe( respuesta => {
			console.log("la respuesta de cargarImagenQR", respuesta )
		})
	}
	workBook = null;
	jsonData = null;
	infoFile = null;
	cargarCartones(evento){
		const lector = new FileReader();
		if(evento.target.files[0]){
			const archivo = evento.target.files[0];
			lector.onload = (ev) =>{
				const data = lector.result
				this.workBook = XLSX.read(data, {type: 'binary'})
				this.jsonData = this.workBook.SheetNames.reduce( (initial, name) => {
					const hoja = this.workBook.Sheets[name];
					initial[name] = XLSX.utils.sheet_to_json(hoja)
					return initial
				}, {})
				// console.log("!", this.jsonData['RESUMEN CARTONES OK'])
				let arreglo_datos = this.jsonData['RESUMEN CARTONES OK']
				this.infoFile = arreglo_datos.map( fila => {
					// console.log("Fila", fila)
					return {
						id: fila['#'],
						datos: [
							{letra: 'B', posicion: 1, valor: fila['B']},
							{letra: 'B', posicion: 2, valor: fila['B_1']},
							{letra: 'B', posicion: 3, valor: fila['B_2']},
							{letra: 'B', posicion: 4, valor: fila['B_3']},
							{letra: 'B', posicion: 5, valor: fila['B_4']},
							{letra: 'I', posicion: 1, valor: fila['I']},
							{letra: 'I', posicion: 2, valor: fila['I_1']},
							{letra: 'I', posicion: 3, valor: fila['I_2']},
							{letra: 'I', posicion: 4, valor: fila['I_3']},
							{letra: 'I', posicion: 5, valor: fila['I_4']},
							{letra: 'N', posicion: 1, valor: fila['N']},
							{letra: 'N', posicion: 2, valor: fila['N_1']},
							{letra: 'N', posicion: 3, valor: fila['N_2']},
							{letra: 'N', posicion: 4, valor: fila['N_3']},
							{letra: 'N', posicion: 5, valor: fila['N_4']},
							{letra: 'G', posicion: 1, valor: fila['G']},
							{letra: 'G', posicion: 2, valor: fila['G_1']},
							{letra: 'G', posicion: 3, valor: fila['G_2']},
							{letra: 'G', posicion: 4, valor: fila['G_3']},
							{letra: 'G', posicion: 5, valor: fila['G_4']},
							{letra: 'O', posicion: 1, valor: fila['O']},
							{letra: 'O', posicion: 2, valor: fila['O_1']},
							{letra: 'O', posicion: 3, valor: fila['O_2']},
							{letra: 'O', posicion: 4, valor: fila['O_3']},
							{letra: 'O', posicion: 5, valor: fila['O_4']},
						]
					}
				})
				// console.log("XXX", this.infoFile)
				this.https.post(`${this.globals.urlApi}v2/bingo-cartones/crear`, {data: this.infoFile}).subscribe( (resData: any) =>{
					console.log("resData", resData)
				});
			}
			lector.readAsBinaryString(archivo);
		}
		
	}
}