import { Injectable } from '@angular/core';

// import * as S3 from 'aws-sdk/clients/s3';
import { S3 } from 'aws-sdk';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UploadService {
	public archivoCargado

	public archivo_encontrado: boolean = false;
	public _archivo_encontrado = new BehaviorSubject(this.archivo_encontrado);

	constructor() {
		this._archivo_encontrado.subscribe( valor =>{
			this.archivo_encontrado = valor
		})
	}

	uploadFile (file, carpeta, namefile ) {
		let contentType = file.type;
		let bucket = new S3({
			accessKeyId: 'AKIAYH47G72QYGFGOTL6',
			secretAccessKey: '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',
			region: 'us-east-1',
			apiVersion: '2006-03-01'
		});
		let params = {
			Bucket: carpeta,
			Key: namefile,
			Body: file,
			ACL: 'public-read',
			ContentType: contentType
		}
		// sin progreso
		bucket.upload(params, function (err, data) {
			if ( err ) {
				console.log('Ocurrió un error al cargar el archivo', err);
				return false;
			}
			// console.log('Archivo cargado satisfactoriamente!!!');
			this.archivoCargado = data;
			return true;
		});
		// con progreso
		/* bucket.upload(params).on('httpUploadProgress', function (evt) {
			console.log(evt.loaded + ' Of ' + evt.total + 'Bytes')
		}).send( function (err, data) {
			if ( err ) {
				console.log('Ocurrió un error al cargar el archivo', err);
				return false;
			}
			console.log('Archivo cargado satisfactoriamente!!!', data);
			return data;
		}) */
	}
	uploadFileConProgreso (file, carpeta, namefile ) {
		let contentType = file.type;
		let bucket = new S3({
			accessKeyId: 'AKIAYH47G72QYGFGOTL6',
			secretAccessKey: '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',
			region: 'us-east-1',
			apiVersion: '2006-03-01'
		});
		let params = {
			Bucket: carpeta,
			Key: namefile,
			Body: file,
			ACL: 'public-read',
			ContentType: contentType
		}
		// con progreso
		bucket.upload(params).on('httpUploadProgress', function (evt) {
			console.log(evt.loaded + ' Of ' + evt.total + 'Bytes')
		}).send( function (err, data) {
			if ( err ) {
				console.log('Ocurrió un error al cargar el archivo', err);
				return false;
			}
			console.log('Archivo cargado satisfactoriamente!!!', data);
			this.archivoCargado = data;
			return data;
		})
	}
	getArchivo (bucket, nombre_archivo){
		let s3 = new S3({
			accessKeyId: 'AKIAYH47G72QYGFGOTL6',
			secretAccessKey: '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',
			region: 'us-east-1',
			apiVersion: '2006-03-01'
		});
		s3.getObject({Bucket: bucket, Key: nombre_archivo}, (err, data:any) =>{
			if (err){
				console.log("El archivo no se encontró...");
				this._archivo_encontrado.next(false)
			}
			if(data){
				/* const string = new TextDecoder('utf-8').decode(data.Body)
				console.log(string) */
				console.log("Archivo encontrado...");
				this._archivo_encontrado.next(data)
			}
			
		})
	}
	
}
