import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
	providedIn: 'root'
})
export class ExportExcelService {
	// https://www.freakyjolly.com/create-excel-from-json-in-angular-98-using-exceljs-tutorial-with-example/#.YFuO9Oj0nIV
	constructor() { }

	exportarProductosCategorias (ExcelData){
		const data = ExcelData.data;

		// crear un libro con una hoja
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet('Hoja1');

		worksheet.columns = [
			{header: 'sku', key: 'sku', width: 15},
			{header: 'categoria', key: 'categoria', width: 40},
			{header: 'subcategoria', key: 'subcategoria', width: 30}
		]

		worksheet.autoFilter = 'A1:C1';
		
		data.forEach(element => {
			worksheet.addRow(element)
		});

		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
			fs.saveAs(blob, 'productosCategorias.xlsx')
		})
	}
	exportarCotizaciones (ExcelData){
		const data = ExcelData.data;

		// crear un libro con una hoja
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet('Hoja1');

		worksheet.columns = [
			{header: 'Fecha creación', key: 'createdAt', width: 15},
			{header: 'nombre', key: 'nombre', width: 35},
			{header: 'empresa', key: 'empresa', width: 35},
			{header: 'correo', key: 'correo', width: 35},
			{header: 'telefono', key: 'telefono', width: 15},
			{header: 'identificacion', key: 'identificacion', width: 15},
			{header: 'direccion', key: 'direccion', width: 15},
			{header: 'ciudad', key: 'ciudad', width: 15},
			{header: 'comentarios', key: 'comentarios', width: 50}
		]

		worksheet.autoFilter = 'A1:I1';
		
		data.forEach(element => {
			let fec = new Date(element.createdAt)
			let ffinal = fec.toISOString().substring(0,10)
			element.createdAt = ffinal
			worksheet.addRow(element)
		});

		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
			fs.saveAs(blob, 'reporte-cotizaciones.xlsx')
		})
	}
	exportarReporte1( ExcelData, nConteo, fecha_conteo, codigo_almacen ){
		const data = ExcelData
		// crear un libro con una hoja
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet('Hoja1');

		worksheet.columns = [
			{header: 'almacen', key: 'almacen', width: 15},
			{header: 'fecha', key: 'fecha', width: 15},
			{header: 'numero conteo', key: 'numero conteo', width: 15},
			{header: 'codigo articulo', key: 'codigo articulo', width: 25},
			{header: 'codigo ie', key: 'codigo ie', width: 15},
			{header: 'descripcion del articulo', key: 'descripcion del articulo', width: 60},
			{header: 'marca', key: 'marca', width: 20},
			{header: 'unidad de medida', key: 'unidad de medida', width: 20},
			{header: 'cantidad contada', key: 'cantidad contada', width: 20},
			{header: 'correo usuario conteo', key: 'correo usuario conteo', width: 30},
			{header: 'ubicacion en bodega', key: 'ubicacion en bodega', width: 15}
		]
		worksheet.autoFilter = 'A1:K1';
		data.forEach(element => {
			element['codigo articulo'] = Number(element['codigo articulo'])
			/* let fec = new Date(element.hora_conteo)
			fec.setHours(fec.getHours() - 5)
			let ffinal = fec.toISOString().substring(11,19)
			element.hora_conteo = ffinal */
			worksheet.addRow(element)
		});
		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
			fs.saveAs(blob, `detalle-conteo-${fecha_conteo}-${codigo_almacen}-${nConteo}`)
		})
	}
	exportarReporte2( ExcelData, codigo_almacen, fecha_conteo ){
		const data = ExcelData
		// crear un libro con una hoja
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet('Hoja1');

		worksheet.columns = [
			{header: 'ubicacion', key: 'ubicacion', width: 10},
			{header: 'codigo sap', key: 'codigo sap', width: 15},
			{header: 'codigo ie', key: 'codigo ie', width: 15},
			{header: 'descripcion', key: 'descripcion del articulo', width: 40},
			{header: 'marca', key: 'marca', width: 15},
			{header: 'ref fabrica', key: 'referencia de fabrica', width: 15},
			{header: 'unidad de medida', key: 'unidad de medida', width: 20},
			{header: 'conteo 1', key: 'conteo 1', width: 15},
			{header: 'conteo 2', key: 'conteo 2', width: 15},
			{header: 'diferencia', key: 'diferencia', width: 15},
		]
		worksheet.autoFilter = 'A1:J1';
		data.forEach(element => {
			element['codigo sap'] = Number(element['codigo sap'])
			worksheet.addRow(element)
		});
		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
			fs.saveAs(blob, `comparativo-conteos-1-2-${codigo_almacen}-${fecha_conteo}`)
		})
	}
	exportarReporte3( ExcelData, fecha_conteo, codigo_almacen ){
		const data = ExcelData
		// crear un libro con una hoja
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet('Hoja1');

		worksheet.columns = [
			{header: 'ubicacion', key: 'ubicacion', width: 10},
			{header: 'codigo sap', key: 'codigo sap', width: 15},
			{header: 'codigo ie', key: 'codigo ie', width: 15},
			{header: 'descripcion', key: 'descripcion del articulo', width: 40},
			{header: 'marca', key: 'marca', width: 15},
			{header: 'ref fabrica', key: 'referencia de fabrica', width: 15},
			{header: 'unidad de medida', key: 'unidad de medida', width: 20},
			{header: 'conteo 1', key: 'conteo 1', width: 15},
			{header: 'conteo 2', key: 'conteo 2', width: 15},
			{header: 'conteo 3', key: 'conteo 3', width: 15},
			{header: 'diferencia_1_2', key: 'diferencia_1_2', width: 15},
		]
		worksheet.autoFilter = 'A1:K1';
		data.forEach(element => {
			element['codigo sap'] = Number(element['codigo sap'])
			worksheet.addRow(element)
		});
		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
			fs.saveAs(blob, `comparativo-conteos-1-2-3-${fecha_conteo}-${codigo_almacen}`)
		})
	}
	exportarReporte4( ExcelData, fecha_conteo, codigo_almacen ){
		const data = ExcelData
		// crear un libro con una hoja
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet('Hoja1');

		worksheet.columns = [
			{header: 'ubicacion', key: 'ubicacion', width: 20},
			{header: 'articulo', key: 'articulo', width: 20},
			{header: 'codigo ie', key: 'codigo ie', width: 20},
			{header: 'descripcion', key: 'descripcion', width: 20},
			{header: 'marca', key: 'marca', width: 20},
			{header: 'referencia de fabrica', key: 'referencia de fabrica', width: 20},
			{header: 'unidad de medida', key: 'unidad de medida', width: 20},
			{header: 'existencia', key: 'existencia', width: 20},
			{header: 'costo unitario', key: 'costo unitario', width: 20}
		]
		worksheet.autoFilter = 'A1:I1';
		data.forEach(element => {
			element.articulo = Number(element.articulo)
			worksheet.addRow(element)
		});
		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
			fs.saveAs(blob, `articulos-con-stock-NO-digitados-conteos-${fecha_conteo}-${codigo_almacen}`)
		})
	}
	exportarReporte5( ExcelData, fecha_conteo, codigo_almacen ){
		const data = ExcelData
		// crear un libro con una hoja
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet('Hoja1');

		worksheet.columns = [
			
			{header: 'ubicacion', key: 'ubicacion', width: 15},
			{header: 'articulo', key: 'articulo', width: 15},
			{header: 'codigo ie', key: 'codigo ie', width: 15},
			{header: 'descripcion', key: 'descripcion', width: 45},
			{header: 'marca', key: 'marca', width: 15},
			{header: 'referencia de fabrica', key: 'referencia de fabrica', width: 15},
			{header: 'unidad de medida', key: 'unidad de medida', width: 15},
			{header: 'existencia', key: 'existencia', width: 15},
			{header: 'cantidad conteo', key: 'cantidad conteo', width: 15},
			{header: 'diferencia', key: 'diferencia', width: 15},
			{header: 'costo unitario', key: 'costo unitario', width: 15},
			{header: 'diferencia costo', key: 'diferencia costo', width: 15},
			{header: 'valor total', key: 'valor total', width: 15},
			{header: 'sku', key: 'sku', width: 15},
			
		]
		worksheet.autoFilter = 'A1:E1';
		data.forEach(element => {
			element.articulo = Number(element.articulo)
			/* let fec = new Date(element.hora_conteo)
			fec.setHours(fec.getHours() - 5)
			let ffinal = fec.toISOString().substring(11,19)
			element.hora_conteo = ffinal
			element.sku = Number(element.sku) */
			worksheet.addRow(element)
		});
		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
			fs.saveAs(blob, `diferencias-existencia-conteos-${fecha_conteo}-${codigo_almacen}`)
		})
	}
	exportarReporte6( ExcelData, fecha_conteo, codigo_almacen ){
		const data = ExcelData
		// crear un libro con una hoja
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet('Hoja1');

		worksheet.columns = [
			{header: 'bodega', key: 'bodega', width: 15},
			{header: 'fecha', key: 'fechacreacion', width: 15},
			{header: 'sku', key: 'ItemCode', width: 15},
			{header: 'nombre', key: 'ItemName', width: 50},
			{header: 'cant_conteo_1', key: 'cant_conteo_1', width: 20},
			{header: 'cant_conteo_2', key: 'cant_conteo_2', width: 20},
			{header: 'cant_conteo_3', key: 'cant_conteo_3', width: 20},
			{header: 'cant_conteo_4', key: 'cant_conteo_4', width: 20},
			/* {header: 'referencia de fábrica', key: 'reffab', width: 30},
			{header: 'código ie', key: 'codigo_ie', width: 30}, */
		]
		worksheet.autoFilter = 'A1:H1';
		data.forEach(element => {
			element.ItemCode = Number(element.ItemCode)
			worksheet.addRow(element)
		});
		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
			fs.saveAs(blob, `detalles-conteos-${fecha_conteo}-${codigo_almacen}`)
		})
	}
	exportarReporte7( ExcelData, fecha_conteo, codigo_almacen ){
		const data = ExcelData
		// crear un libro con una hoja
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet('Hoja1');

		worksheet.columns = [
			{header: 'bodega', key: 'bodega', width: 15},
			{header: 'fecha', key: 'fechacreacion', width: 15},
			{header: 'sku', key: 'ItemCode', width: 15},
			{header: 'nombre', key: 'ItemName', width: 50},
			{header: 'cant_conteo_1', key: 'cant_conteo_1', width: 20},
			{header: 'cant_conteo_2', key: 'cant_conteo_2', width: 20},
			{header: 'cant_conteo_3', key: 'cant_conteo_3', width: 20},
			{header: 'cant_conteo_4', key: 'cant_conteo_4', width: 20},
			{header: 'conteo_final', key: 'conteo_final', width: 20}
		]
		worksheet.autoFilter = 'A1:I1';
		data.forEach(element => {
			element.ItemCode = Number(element.ItemCode)
			worksheet.addRow(element)
		});
		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
			fs.saveAs(blob, `informe-final-${fecha_conteo}-${codigo_almacen}`)
		})
	}
	//informes picking-packing
	ppInformeGeneral(ExcelData){
		const data = ExcelData.data;

		// crear un libro con una hoja
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet('Hoja1');

		worksheet.columns = [
			{header: 'Documento', key: 'documento', width: 15},
			{header: 'Paso', key: 'paso', width: 40},
			{header: 'Hora Registro', key: 'hora_registro', width: 30},
			{header: 'Quien Registró', key: 'quien_registro', width: 30},
			{header: 'Encargado', key: 'quien_encargado', width: 30}
		]

		worksheet.autoFilter = 'A1:E1';
		
		data.forEach(element => {
			worksheet.addRow(element)
		});

		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
			fs.saveAs(blob, 'picking-packing-general.xlsx')
		})
	}
	ppInformeGeneralV2(ExcelData){
		const data = ExcelData.data;

		// crear un libro con una hoja
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet('Hoja1');

		worksheet.columns = [
			{header: 'Id Documento', key: 'id_documento', width: 15},
			{header: 'Documento', key: 'documento', width: 15},
			{header: 'Cliente', key: 'cliente', width: 40},
			{header: 'Condición entrega', key: 'condicion_entrega', width: 30},
			{header: 'Ingreso Documento', key: 'ingreso_documento', width: 30},
			{header: 'Asignar picker', key: 'asignar_picker', width: 30},
			{header: 'Picking finalizado', key: 'picking_finalizado', width: 30},
			{header: 'Packing', key: 'packing', width: 30},
			{header: 'Packing bodega', key: 'packing_bodega', width: 30},
			{header: 'Packing transporte', key: 'packing_transporte', width: 30},
			{header: 'Entregado', key: 'entregado', width: 30},
			{header: 'Borrado', key: 'borrado', width: 30},
			{header: 'Ciudad', key: 'la_ciudad', width: 30},
			{header: 'Registró', key: 'quien_registro', width: 50},
			{header: 'Picker', key: 'quien_picker', width: 50},
		]

		worksheet.autoFilter = 'A1:O1';
		
		data.forEach(element => {
			worksheet.addRow(element)
		});

		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
			fs.saveAs(blob, 'picking-packing-general-ciudad.xlsx')
		})
	}
}
