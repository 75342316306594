import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppService } from '../../app.service';
import { Producto } from '../../models/producto';
import { ProductoService } from '../../services/producto.service';
import { Globals } from '../../globals';
import { HttpClient } from '@angular/common/http';

// import { NgxAnalyticsFacebook } from 'ngx-analytics/facebook';

@Component({
	selector: 'app-controls',
	templateUrl: './controls.component.html',
	styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
	@Input() product: Producto;
	@Input() type: string;
	@Input() enstock: number;
	@Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
	@Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
	public count: number = 1;
	public align = 'center center';
	cantidadComparaciones: number = 4;
	public maxCant: number;
	public cantElegida: number;

	public stock:number = 0;

	constructor(
		private appService: AppService,
		public snackBar: MatSnackBar,
		public productoService: ProductoService,
		private globals: Globals,
		private http: HttpClient
		/* private ngxAnalyticsFacebook: NgxAnalyticsFacebook */
	) {
		
	}

	ngOnInit() {
		this.count = 1
		if (this.product) {
			this.setStock();
			if (this.product.enCarrito > 0) {
				this.count = this.product.enCarrito;
			} else {
				this.count = 1;
			}
			/* if(this.product.idsap == '106105102018'){
				this.product.precio = 104622
				this.product.precioecommerce = 124500
			} */
		}
		this.layoutAlign();
	}
	ngOnChanges(changes: SimpleChanges): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.
		
		this.count = 1
	}

	public layoutAlign() {
		if (this.type === 'all') {
			this.align = 'space-between center';
		} else if (this.type === 'wish') {
			this.align = 'start center';
		} else {
			this.align = 'center center';
		}
	}

	public increment() {
		if (this.count < this.stock) {
			this.count++;
			const obj = {
				productId: this.product.idsap,
				soldQuantity: this.count,
				total: this.count * this.product.precioecommerce
			};
			this.product.enCarrito = this.count;
			this.changeQuantity(obj);
		} else {
			this.snackBar.open('No puedes elegir mas productos de los disponibles. En stock ' + this.count + ' items.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
		}
	}

	public decrement() {
		if (this.count > 1) {
			this.count--;
			const obj = {
				productId: this.product.idsap,
				soldQuantity: this.count,
				total: this.count * this.product.precioecommerce
			};
			this.product.enCarrito = this.count;
			this.changeQuantity(obj);
		}
	}
	setCantidad(valor) {
		if (parseInt(valor, 0) <= this.stock && valor >= 1) {
			this.count = valor;
			const obj = {
				productId: this.product.idsap,
				soldQuantity: parseInt(valor, 0),
				total: valor * this.product.precioecommerce
			};
			this.product.enCarrito = valor
			this.changeQuantity(obj);
		} else if (parseInt(valor, 0) > this.stock ) {
			this.snackBar.open('Límite alcanzado', null, { panelClass: 'error', verticalPosition: 'top', duration: 500 });
			const obj = {
				productId: this.product.idsap,
				soldQuantity: 0,
				total: 0 * this.product.precioecommerce
			};
			this.product.enCarrito = 0;
			this.changeQuantity(obj);
		}
	}

	public addToCompare(product: Producto) {
		if (this.appService.Data.compareList.length < this.cantidadComparaciones ) {
			this.appService.addToCompare(product);
		} else {
			this.snackBar.open('Solo puede agregar ' + this.cantidadComparaciones + ' productos a la compación', '×', { panelClass: 'error', verticalPosition: 'top', duration: 2000 });
		}
	}

	public addToWishList(product: Producto) {
		this.appService.addToWishList(product);
	}

	public addToCart(product: Producto) {
		// console.log('agregado', product)
		let SKU = product.idsap.toString();
		/* this.ngxAnalyticsFacebook.eventTrack('AddToCart', {
			content_ids: [product.id, SKU, product.reffab],
			content_name: product.nombre,
			content_type: 'product',
			contents: JSON.parse(JSON.stringify(product)),
			currency: 'COP',
			value: product.precioecommerce }
		); */
		// content_ids, content_name, content_type, contents, currency, value
		const currentProduct = this.appService.Data.cartList.filter(item => item.idsap == product.idsap)[0];
		if (currentProduct) {
			if ((parseInt(currentProduct.enCarrito.toString(), 0) + parseInt(this.count.toString(), 0)) <= this.stock) {
				product.enCarrito = parseInt(currentProduct.enCarrito.toString(), 0) + parseInt(this.count.toString(), 0);
			} else {
				this.snackBar.open('No puedes elegir mas productos de los disponibles. En stock ' + this.stock + ' productos y ya agregaste ' + currentProduct.enCarrito + ' productos a tu carrito', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
				return false;
			}
		} else {
			product.enCarrito = parseInt(this.count.toString(), 0);
		}
		this.appService.addToCart(product);
	}

	public openProductDialog(event) {
		this.onOpenProductDialog.emit(event);
	}

	public changeQuantity(value) {
		this.onQuantityChange.emit(value);
	}
	public setStock(){
		if(this.enstock > 0) {
			this.stock = this.enstock
		} else {
			let prodTemp: any = this.product
			let tengo_cortas = prodTemp?.original?.ManBtchNum === 'Y' ? true : false;
		
			if(tengo_cortas){
				let ciudad = this.appService?.ciudadActual?.plano_name
				
				this.http.get(`${this.globals.urlApi}v2/cortas-prducto-ciudad/${ciudad}/${this.product.idsap}`).subscribe( (resDataCortas: any) =>{
					let cants = resDataCortas.data.map(el => parseInt(el.Quantity))
					this.stock = Math.max(...cants)
				})
			}else {
				if( this.appService.ciudadActual ){
					
					// console.log("1", this.appService.ciudadActual)
					/* this.http.post(this.globals.urlApi + 'producto-ciudad/stock-ciudad', {data: { sku: this.product.idsap, ciudad: this.appService.ciudadActual.plano_name}}).subscribe((datosStock:any) => {
						if (datosStock.stock){
							this.stock = datosStock.stock;
							this.product.stock = this.stock;
						} else { 
							this.stock = 0;
						}
		
					}) */
					// this.http.
					this.http.get(`https://ie-vie.com:3000/api/v2/stock-de-ciudad/${this.appService.ciudadActual.plano_name}/${this.product.idsap}`).subscribe( (res: any) => {
						if(res.status === 'ok'){
							if(res.data[0]){
								this.stock = ((res.data[0].onhand) - (res.data[0].iscommited));
								this.product.stock = this.stock;
							}
						} else {
							this.stock = 0
						}
					})
				} else {
					this.appService.compartidor.subscribe(ciudadActual =>{
						// console.log("2", ciudadActual)
						
						if(ciudadActual) {
							/* this.http.post(this.globals.urlApi + 'producto-ciudad/stock-ciudad', {data: { sku: this.product.idsap, ciudad: ciudadActual?.plano_name}}).subscribe((datosStock: any) => {
								if (datosStock.stock){
									this.stock = datosStock.stock;
									this.product.stock = this.stock;
								} else { 
									this.stock = 0;
								}
							}) */
							
							this.http.get(`https://ie-vie.com:3000/api/v2/stock-de-ciudad/${ciudadActual.plano_name}/${this.product.idsap}`).subscribe( (res: any) => {
								if(res.status === 'ok'){
									this.stock = res.data[0].onhand - res.data[0].iscommited;
									this.product.stock = this.stock;
								} else {
									this.stock = 0
								}
							})
						} else {
							this.stock = 0
						}
					})
					
				}
			}
		}
		
	}
	/* public cambioValor($event){
		console.log("cantidad : ", $event)
	} */

}
