import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Globals } from '../../globals';
import { AppService } from '../../app.service';
import { Router } from '@angular/router';
import { NgxCaptchaModule } from 'ngx-captcha';

@Component({
	selector: 'app-formulario-bingo',
	templateUrl: './formulario-bingo.component.html',
	styleUrls: ['./formulario-bingo.component.scss']
})
export class FormularioBingoComponent implements OnInit {
	datosParticipanteBingoFormGroup: UntypedFormGroup
	recaptchaValidado: boolean = false
	aceptoTratamientoDatos: boolean = false;

	tokenCaptcha = '6LfAkhoaAAAAABTbyWODa_6m58zqawfcrIhVm_CE';
	size
	// https://www.youtube.com/watch?v=AYznH6MBXM8

	/* @ViewChild('recaptcha', {static: false }) recaptchaElement: ElementRef */
	// @ViewChild('captchaElem', {static: false })
	validado: boolean = false;

	constructor(
		private _formBuilder: UntypedFormBuilder,
		private https: HttpClient,
		private appService: AppService,
		private globals: Globals,
		private router: Router
	) {
		this.datosParticipanteBingoFormGroup = this._formBuilder.group({
			nombre: ['', Validators.required],
			ciudad: ['', Validators.required],
			direccion: ['', Validators.required],
			telefono: ['', Validators.required],
			email: ['', Validators.required],
			factura: ['', Validators.required],
			tyc: [false, Validators.requiredTrue],
			recaptcha: ['', Validators.required]
		})
	}

	ngOnInit(): void {
	}
	ngAfterViewInit() {
		//this.renderReCaptcha()
	}

	handleReset(){}
	handleExpire(){}
	
	handleLoad(){

	}
	handleSuccess(event){
		// this.datosParticipanteBingoFormGroup.
		console.log("captcha validado!")
		// console.log("xxx =>", event)
	}

	registrarPArticipante(datos){
		this.https.get('https://ie-vie.com:3000/api/participantes-bingo/participante/' + datos.email).subscribe( (resdata: any) => {
			/* if (resdata){
				this.appService.mensajeSnackBar('El correo ya se encuentra registrado', 3000, 'warning', 'x');	
			} else { */
				this.https.post('https://ie-vie.com:3000/api/participantes-bingo/registrar', datos).subscribe( (res:any) => {
					if(res){
						this.https.post(this.globals.backServiciosECommerce + 'bingo/correo_registro_bingo.php', datos).subscribe( (enviado: any) => {
							if(enviado.status == 'ok'){
								this.appService.mensajeSnackBar('Registrado con éxito!!!', 3000, 'success', 'x');
								this.router.navigate(['/']);
							}
						})
					}
				})
			//}
		})		
	}

	/* addRecaptchaScript() {
		window['grecaptchaCallback'] = () => {
			this.renderReCaptcha();
		}
		(function(d, s, id, obj){
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) { return;}
			js = d.createElement(s); js.id = id;
			js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'recaptcha-jssdk', this));
	   
	} */
	/* renderReCaptcha() {
		window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
		  'sitekey' : '6LfAkhoaAAAAABTbyWODa_6m58zqawfcrIhVm_CE',
		  'callback': (response) => {
			  // console.log(response);
			  this.https.post(this.globals.backServiciosECommerce + 'captcha.php', {response}).subscribe((res:any) =>{
				  // console.log("res captcha ==> ", res)
				  	if(res.success === true) {
						
						this.validado = true;
						
					  	setInterval(function(){
						
							this.validado = false
						
						}, 120000);
				  	}
			  })
		  }
		});
	} */


}
