/* import { WINDOW } from '@ng-toolkit/universal'; */
import { Component, OnInit, ViewChild, Inject, ElementRef, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { AppService } from '../../app.service';
import { ProductoService } from '../../services/producto.service';
import { TccService } from '../../services/tcc.service';
import { CiudadestccService } from '../../services/ciudadestcc.service';
import { GeocodeService } from '../../services/geocode.service';
import { Ubicacion } from '../../models/ubicacion';
import { Ciudadtcc } from '../../models/ciudadtcc';
import { EcollectService } from '../../services/ecollect.service';
import { IeAuthService } from '../../services/ieauth.service';
import { Globals } from '../../globals';
import { Producto } from '../../models/producto';
import { PedidosService } from '../../services/pedidos.service';
import { CuentaService } from '../../services/cuenta.service';
import { PagesComponent } from '../pages.component';
import { WompiService } from '../../services/wompi.service';

// dialogo cupón
export interface DialogData {
	cuponActivo: any,
	valorProductos: number,
	idCuenta: string,
}
@Component({
	selector: 'app-cupon-modal',
	templateUrl: 'cuponModal.html',
})
export class DialogoCuponComponent {
	codigo: string
	cuponNoValido: boolean = false;
	mensajeError: string =  '';
	constructor(
		public dialogRef: MatDialogRef<DialogoCuponComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private httpClient: HttpClient,
		private globals: Globals,
		public appService : AppService
	) {}

	onNoClick(): void {
		this.dialogRef.close();
	}
	verificarValidezCuponCuenta(registro: any, valor) {
		return registro.idCuenta == valor
	}
	onYesClick(): void {
		this.appService.mensajeCarga = 'Validando Cupon';
		this.httpClient.post(this.globals.urlApi + 'cupones/cupon', {codigo : this.codigo}).subscribe( (cupon: any) => {
			if (cupon) {
				let fechaCaducidad = new Date(cupon.caducidad);
				let fechaActual = new Date();
				// verificar si hay cuentas para validación
				let cuentaValida = cupon.cupones_cuentas.find( opcion => opcion.idCuenta === this.data.idCuenta);
				if (cuentaValida || cupon.cupones_cuentas.length == 0) {
					if (fechaCaducidad > fechaActual) {
						if ((this.data.valorProductos > cupon.gastomin) && ( this.data.valorProductos < cupon.gastomax || cupon.gastomax == 0) ) {
							this.dialogRef.close(cupon);
						} else {
							this.mensajeError = 'No aplica para los valores en el carrito';
							this.cuponNoValido = true;
						}
					} else {
						this.mensajeError = 'Cupón caducado';
						this.cuponNoValido = true;
					}
				} else {
					this.mensajeError = 'Cupón no válido para el usuario';
					this.cuponNoValido = true;
				}
			} else {
				this.mensajeError = 'El código ingresado no es válido';
				this.cuponNoValido = true;
			}
		})
	}
}
// fin dialogo cupón

// dialogo envio
export interface DialogData {
	cuponActivo: any,
	valorProductos: number,
	idCuenta: string,
}
@Component({
	selector: 'app-envio-modal',
	templateUrl: 'envioModal.html',
})
export class DialogoEnvioComponent {
	codigo: string
	cuponNoValido: boolean = false;
	mensajeError: string =  '';
	constructor(
		public dialogRef: MatDialogRef<DialogoEnvioComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private httpClient: HttpClient,
		private globals: Globals,
		public appService : AppService,
		private route: Router
	) {}
	onNoClick(): void {
		this.route.navigate(['/'])
		this.dialogRef.close();
	}
	navegarA(url: string) {
		this.route.navigate([url]);
		this.dialogRef.close();
	}
	verificarValidezCuponCuenta(registro: any, valor) {
		return registro.idCuenta == valor
	}
	onYesClick(): void {
		this.appService.mensajeCarga = 'Validando Cupon';
		this.httpClient.post(this.globals.urlApi + 'cupones/cupon', {codigo : this.codigo}).subscribe( (cupon: any) => {
			if (cupon) {
				let fechaCaducidad = new Date(cupon.caducidad);
				let fechaActual = new Date();
				// verificar si hay cuentas para validación
				let cuentaValida = cupon.cupones_cuentas.find( opcion => opcion.idCuenta === this.data.idCuenta);
				// if (cuentaValida || cupon.cupones_cuentas.length == 0) {
					if (fechaCaducidad > fechaActual) {
						if ((this.data.valorProductos > cupon.gastomin) && ( this.data.valorProductos < cupon.gastomax || cupon.gastomax == 0) ) {
							this.dialogRef.close(cupon);
						} else {
							this.mensajeError = 'No aplica para los valores en el carrito';
							this.cuponNoValido = true;
						}
					} else {
						this.mensajeError = 'Cupón caducado';
						this.cuponNoValido = true;
					}
				/* } else {
					this.mensajeError = 'Cupón no válido para el usuario';
					this.cuponNoValido = true;
				} */
			} else {
				this.mensajeError = 'El código ingresado no es válido';
				this.cuponNoValido = true;
			}
		})
	}
}
// fin dialogo envio

// dialogo pago cupo
export interface DialogData {
	listadoProductosReg: any,
	listadoProductosNeg: any,
	cupoDisponible: number,
	productosCompra:any,
	valorFlete:number,
	liquidacionPedidoCupo: any,
	estadoNegociacion: boolean
}
@Component({
	selector: 'app-pagocupo-modal',
	templateUrl: 'pagocupoModal.html',
	styles: ['.mat-header-row { border-bottom: 1px solid gray;} .titulos{ color: #000 !important;}']
})
export class DialogoPagoCupoComponent {
	valoresNegociacion: any
	neg_valorProductos:number = 0
	neg_flete:number = 0
	neg_subtotal:number = 0
	neg_iva:number = 0
	neg_total:number = 0
	cupoDisponible: number = 0
	
	constructor(
		public dialogRef: MatDialogRef<DialogoPagoCupoComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private http: HttpClient,
		private globals: Globals,
		public appService : AppService,
		private route: Router
	) {
		if(data.listadoProductosNeg) {
			this.getValoresNegociacion(data.productosCompra, data.listadoProductosNeg)
		} else {
			this.cupoDisponible = -1
			this.neg_total = 0
		}
		
		this.neg_flete = data.valorFlete			
		this.data.estadoNegociacion = false
		this.cupoDisponible = this.data.cupoDisponible
	}
	onNoClick(): void {
		this.dialogRef.close();
	}
	verificarValidezCuponCuenta(registro: any, valor) {
		return registro.idCuenta == valor
	}
	onYesClick(): void {
		this.appService.mensajeCarga = 'Validando...';
		this.data.estadoNegociacion = true
	}
	getValoresNegociacion (productos, campos) {
		console.log("campos", campos)
		let arregloIdProductos = [];
		productos.forEach(element => {
			arregloIdProductos.push(element.idsap)
		});

		let datos = {
			productos : arregloIdProductos,
			campos: campos
		}
		this.http.post(this.globals.urlApi + 'negociaciones/productos', datos).subscribe(res =>{
			let pedidoConCupo = res
			this.valoresNegociacion = pedidoConCupo
			this.procesar(this.appService.Data.cartList, this.valoresNegociacion);
		})
	}
	procesar(carrito: any, negociaciones: any[]) {
		carrito.forEach(producto => {
			let item = negociaciones.find( elemento => elemento.idsap  == producto.idsap)
			let vara = item.treinta ? item.treinta : item.sesenta ? item.sesenta : item.noventa ? item.noventa : item.cientoveinte ? item.cientoveinte : item.contado
			if( vara ) {
				producto.precioNegociacion = vara
			} else {
				producto.precioNegociacion = producto.precioecommerce
			}
			
			this.neg_valorProductos += producto.precioNegociacion * producto.enCarrito;
		})
		this.neg_subtotal = this.neg_valorProductos + this.neg_flete
		this.neg_iva = this.neg_subtotal*0.19
		this.neg_total = this.neg_subtotal + this.neg_iva

		this.data.liquidacionPedidoCupo = {
			"totalProductos": this.neg_valorProductos,
			"remesa": this.neg_flete,
			"cupon" : 0,
			"descuento_cupon": 0,
			"subtotal": this.neg_subtotal,
			"iva": this.neg_iva,
			"granTotal": this.neg_total
		}
	}
}

// fin dialogo pago cupo

// INICIO COMPONENTE
declare var OpenPay: any;

@Component({
	selector: 'app-checkout',
	templateUrl: './checkout.component.html',
	styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
	@ViewChild('horizontalStepper') horizontalStepper: MatStepper;
	@ViewChild('verticalStepper') verticalStepper: MatStepper;

	pixel3m: string = ''

	billingForm: UntypedFormGroup;
	deliveryForm: UntypedFormGroup;
	paymentForm: UntypedFormGroup;
	envioForm: UntypedFormGroup;
	entregaForm: UntypedFormGroup;
	countries = [];
	months = [];
	years = [];
	tiposDocumentos = [];
	deliveryMethods = [];
	subTotal: number = 0;
	grandTotal = 0;
	costoTotalFletes = 0;
	respuestaLiquidacionEnvio: any = {
		status: null
	};
	valorMercancia: number = 0;
	pasos = 0;
	public checked = false;
	mismosDatosFacEnt: boolean = false;

	myControl = new UntypedFormControl();
	ciudadesHabilitadas: Ciudadtcc[];
	ciudadesFiltradas: Observable<Ciudadtcc[]>;

	bodegasProductos: any;
	ciudadDestino: string = '';

	// agrupamiento de productos
	productosinfo: any[];
	listadoCercanasProductos: any[];
	finOpts: any
	habilitarBuscarOrigen: boolean = false;
	ordenar: boolean = false;
	ciudadOrigen: string = '';

	// variables para tratamiento
	productosPedido: any[];
	productosPedidoCiudad: any[];

	ubicacion: Ubicacion;
	loading: boolean;
	deshabilitarbtnpagar: boolean = false;

	// paqueteria
	pesoPaquete = 0;
	anchoPaquete;
	largoPaquete;
	altoPaquete;
	volumenPaquete = 0;
	valorPaquete: number = 0;
	valorLiquidacionTcc: number = 0;
	idDestino = "-1";
	idOrigen = "-2";
	excesoDimensiones: boolean = false;
	excesoDimensionesCiudad : boolean = false;
	productosSobreDimensionados = [];

	// usuario
	usuario
	idCuenta

	// info pedido
	valorProductos: number = 0;
	jsonTemp: any
	valLiqTemp
	valorFleteTemp: number = 0;

	// cupones
	cuponActivo: any;
	descuentoCupon: number = 0;

	//cupo de pago
	diasPago: number = 0;
	bloqueoNegociacion: string = "";
	cupoDisponible: number = 0;
	tempNegociacion: any;
	valoresNegociacion: any;
/*
	neg_ValorProductos: number = 0;
	neg_flete:number = 0;
	neg_subtotal:number = 0;
	neg_iva:number = 0;
	neg_total:number = 0;
*/
	//OPENPAY
	openpay: any;
	onSuccess:any
	onError:any
	datosOpen: any;
	correOpen: string = ''

	constructor(
		/* @Inject(WINDOW) public window: Window, */
		public appService: AppService,
		public formBuilder: UntypedFormBuilder,
		public productoService: ProductoService,
		private http: HttpClient,
		public tccService: TccService,
		private ecollectService: EcollectService,
		public ciudadesTccService: CiudadestccService,
		public geocodeService: GeocodeService,
		public snackBar: MatSnackBar,
		private ieAuthService: IeAuthService,
		private globals: Globals,
		private router: Router,
		private pedidoService: PedidosService,
		private cuentaService: CuentaService,
		public dialogo: MatDialog,
		private modalPago: PagesComponent,
		private wompi: WompiService,
		private renderer2: Renderer2
	) {
		if (this.ieAuthService.getCurrentUser() == undefined) {
			this.router.navigate(['/acceder']);
		} else {
			this.usuario = this.ieAuthService.getCurrentUser()
			this.appService.getUsuarioSAP(this.usuario.email)
			this.getDiasNegociacion()
		}
		
	}
	ngOnInit() {
		this.subTotal = 0;
		this.grandTotal = 0;
		this.correOpen = this.usuario.email;
		this.cargarCiudades();
		this.productosinfo = [];
		if (this.appService.Data.cartList.length > 0) {
			this.inicioDatos();
			this.appService.Data.cartList.forEach(product => {
				this.valorProductos += product.enCarrito * (product.precioecommerce / 1.19);
			});
			this.subTotal = this.valorProductos + this.valorLiquidacionTcc;
			this.grandTotal = this.subTotal + (this.subTotal * 0.19);
			
		}
		this.tiposDocumentos = this.appService.getTiposDocumento();
		this.deliveryMethods = this.appService.getDeliveryMethods();
		this.entregaForm = this.formBuilder.group({
			tipoDocumento: ['', Validators.required],
			numeroDocumento: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{5,15}$')])],
			firstName: [
				(this.usuario) ? this.usuario.firstName : 'Nombre(s)',
				Validators.required
			],
			lastName: [
				(this.usuario) ? this.usuario.lastName : 'Apellido(s)',
				Validators.required
			],
			razonSocial: ['Razón social', Validators.required],
			email: [
				(this.usuario) ? this.usuario.email : '',
				Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])
			],
			phone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{5,15}$')])],
			ciudad: [(this.ciudadDestino) ? this.ciudadDestino : 'La ciudad seleccionada en el paso anterior', Validators.required],
			address: ['', Validators.required]
		});
		this.billingForm = this.formBuilder.group({
			tipoDocumento: ['', Validators.required],
			numeroDocumento: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{5,15}$')])],
			firstName: [
				(this.usuario) ? this.usuario.firstName : 'Nombre(s)',
				Validators.required
			],
			lastName: [
				(this.usuario) ? this.usuario.lastName : 'Apellido(s)',
				Validators.required
			],
			razonSocial: ['Razón social', Validators.required],
			email: [
				(this.usuario) ? this.usuario.email : '',
				Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])
			],
			phone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{5,15}$')])],
			ciudad: ['', Validators.required],
			address: ['', Validators.required]
		});
		this.deliveryForm = this.formBuilder.group({
			myControl: ['', Validators.required]
		});
		this.paymentForm = this.formBuilder.group({
			cardHolderName: ['', Validators.required],
			cardNumber: ['', Validators.required],
			expiredMonth: ['', Validators.required],
			expiredYear: ['', Validators.required],
			cvv: ['', Validators.required]
		});
		// this.verModalEnvio();
	}
	ngAfterViewInit(): void {
		/* const DSLScript = document.createElement('script');
		DSLScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDYjscpzSeP7Eg_4OM43m1PUFPBkluPG4U'; // replace by your API key
		DSLScript.type = 'text/javascript';
		document.body.appendChild(DSLScript); */
		// document.body.removeChild(DSLScript);
		
	}
	cargarCiudades() {
		this.ciudadesTccService.getCiudadesTcc().subscribe(async res => {
			this.ciudadesHabilitadas = await res;
			this.ciudadesFiltradas = this.myControl.valueChanges.pipe(
				startWith<string | Ciudadtcc>(''),
				map(value => typeof value === 'string' ? value : value.NOMBRE_POBLACION),
				map(NOMBRE_POBLACION => NOMBRE_POBLACION ? this._filter(NOMBRE_POBLACION) : this.ciudadesHabilitadas.slice())
			);
		});
	}
	placeOrder() {
		this.horizontalStepper._steps.forEach(step => step.editable = false);
		this.verticalStepper._steps.forEach(step => step.editable = false);
		this.appService.Data.cartList.length = 0;
		this.appService.Data.totalPrice = 0;
		this.appService.Data.totalCartCount = 0;
	}
	getDiasNegociacion() {
		// ojhzmf@gmail.com
		let correo = '';
		/*if(this.usuario.email == 'janier007@gmail.com') {
			correo = 'ojhzmfgh@gmail.com'
		} else {
			correo = this.usuario.email
		}*/
		correo = this.usuario.email;
		// console.log(`${this.globals.urlApi}v2/clientes/cliente/correo/${correo}`)
		
		//this.http.get(this.globals.urlApi + 'usuarios/correo=' + correo ).subscribe((res: any[]) => {
		this.http.get(`${this.globals.urlApi}v2/clientes/cliente/correo/${correo}`).subscribe((res: any[]) => {
			// console.log("# correo 496 ", res)
			if(res && res.length > 0){
				this.datosOpen = res[0];
				this.diasPago = res[0].dias;
				this.bloqueoNegociacion = res[0].bloqueo;
				this.cupoDisponible = res[0].disponible ? res[0].disponible : 0;
				this.tempNegociacion = this.camposNegociacion(res[0].dias)
			}
		})
	}
	displayFn(ciudadtcc?: any): string | Ciudadtcc {
		if (ciudadtcc) {
			this.ciudadDestino = ciudadtcc.NOMBRE_POBLACION + '-' + ciudadtcc.DEPARTAMENTO;
			this.habilitarBuscarOrigen = true
		} else {
			this.ciudadDestino = undefined
			this.habilitarBuscarOrigen = false
		}
		return ciudadtcc ? ciudadtcc.NOMBRE_POBLACION + '-' + ciudadtcc.DEPARTAMENTO : undefined;
	}
	_filter(value: string): Ciudadtcc[] {
		const filterValue = value.toLowerCase();
		return this.ciudadesHabilitadas.filter(option => this.appService.accent_fold(option.NOMBRE_POBLACION.toLowerCase()).indexOf(filterValue) === 0);
	}
	getCodigoDANECiudadTcc(ciudadABuscar: string) {
		if (ciudadABuscar == 'cali') { ciudadABuscar = 'santiago de cali' }
		return this.ciudadesHabilitadas.find(elemento => this.appService.accent_fold(elemento.NOMBRE_POBLACION.toLowerCase()).indexOf(this.appService.accent_fold(ciudadABuscar.toLowerCase())) === 0).DANE
	}
	getCodigoSIONCiudadTcc(ciudadABuscar: string) {
		if (ciudadABuscar == 'cali') { ciudadABuscar = 'santiago de cali' }
		return this.ciudadesHabilitadas.find(elemento => this.appService.accent_fold(elemento.NOMBRE_POBLACION.toLowerCase()).indexOf(this.appService.accent_fold(ciudadABuscar.toLowerCase())) === 0).CODIGO_SION
	}
	liquidacionSoapTcc(evento?) {
		this.excesoDimensiones = false;
		this.excesoDimensionesCiudad = false;
		this.jsonTemp = {}
		this.loading = true;
		this.ciudadDestino = evento ? evento.NOMBRE_POBLACION.toLowerCase() : this.ciudadDestino;
		console.log("la ciudad de destino", this.ciudadDestino)
		this.entregaForm.patchValue({ ciudad: this.ciudadDestino })
		this.ciudadOrigen = this.ciudadOrigen.toLowerCase();
		// console.log('le city', this.ciudadOrigen);
		let productosEnvio: Producto[] = this.appService.Data.cartList;
		this.getCiudadOrigenMercancia();
		setTimeout(() => {
			for (const ciudad in this.appService.productosCiudad) {
				if (this.appService.productosCiudad.hasOwnProperty(ciudad)) {
					if (typeof (this.appService.productosCiudad[ciudad]) == 'object') {
						this.jsonTemp[ciudad] = {};
						for (const producto in this.appService.productosCiudad[ciudad]) {
							if (this.appService.productosCiudad[ciudad].hasOwnProperty(producto)) {
								if (typeof (this.appService.productosCiudad[ciudad][producto]) == 'object') {
									this.jsonTemp[ciudad][producto] = {};
									for (const prop in this.appService.productosCiudad[ciudad][producto]) {
										if (this.appService.productosCiudad[ciudad][producto].hasOwnProperty(prop)) {
											this.jsonTemp[ciudad][producto][prop] = this.appService.productosCiudad[ciudad][producto][prop];
											this.jsonTemp[ciudad][producto].remesado = false;
										}
									}
								} else {
									this.jsonTemp[ciudad][producto] = this.appService.productosCiudad[ciudad][producto];
								}
							}
						}
					}
				}
			}
			let pesoPaquete = 0;
			let anchoPaquete;
			let largoPaquete;
			let altoPaquete;
			let volumenPaquete = 0;
			let valorPaquete: number = 0;
			this.productosSobreDimensionados = [];
			productosEnvio.forEach(producto => {
				// verificación dimensiones productos
				if (producto.ancho > 210 || producto.alto > 210 || producto.longitud > 210 ) {
					this.excesoDimensiones = true;
					this.productosSobreDimensionados.push(producto)
					if (this.appService.accent_fold(this.ciudadDestino.toLowerCase()) == this.ciudadOrigen) {
						this.excesoDimensionesCiudad = true
					}
				}
				let volumenProducto = 0;
				// delete producto.volumen; // 20190919
				// if (!producto.volumen || producto.volumen == 0) {
				volumenProducto = (((producto.ancho/100) * (producto.longitud/100) * (producto.alto/100))); /*  * producto.enCarrito */ // desde SAP SIEMPRE ESTAN EN CENTÍMETROS
				// } else {
				// 	volumenProducto = (producto.volumen /* * producto.enCarrito */)
				// }
				volumenPaquete += (volumenProducto * producto.enCarrito );
				pesoPaquete += ((producto.peso / 1000) * producto.enCarrito);
				valorPaquete += (producto.precioecommerce * producto.enCarrito);
			});
			this.valorMercancia += valorPaquete;
			// TODO: validar que las dimensiones existen para no crear un cubo
			anchoPaquete = altoPaquete = largoPaquete = Math.pow(volumenPaquete, 1 / 3);

			let objetopost = {
				'id_ciudad_origen': this.tccService.getCodigoDANECiudadTcc(this.ciudadesHabilitadas, this.ciudadOrigen),
				'id_ciudad_destino': this.tccService.getCodigoDANECiudadTcc(this.ciudadesHabilitadas, this.ciudadDestino),
				'valor_mercancia': valorPaquete,
				'peso_real': pesoPaquete,
				'peso_volumen': (volumenPaquete * 400),
				'alto': altoPaquete,
				'largo': largoPaquete,
				'ancho': anchoPaquete
			}
			this.idOrigen = this.tccService.getCodigoDANECiudadTcc(this.ciudadesHabilitadas, this.ciudadOrigen);
			this.idDestino = this.tccService.getCodigoDANECiudadTcc(this.ciudadesHabilitadas, this.ciudadDestino);
			this.http.post(this.globals.backTcc + 'consultar_liquidacion.php', objetopost).subscribe((res: any) => {
				// console.log('le res', res)
				if (res) {
					this.respuestaLiquidacionEnvio = res
					if (res.data.consultarliquidacion2Result.total) { // cero "0" es la respuesta positiva
						this.deliveryForm.disable()
						this.pesoPaquete = pesoPaquete / 1000
						this.anchoPaquete = anchoPaquete / 1000
						this.largoPaquete = largoPaquete / 1000
						this.altoPaquete = altoPaquete / 1000
						this.volumenPaquete = volumenPaquete
						this.valorLiquidacionTcc = parseInt(res.data.consultarliquidacion2Result.total.totaldespacho, 0)
						if (this.excesoDimensiones) {
							// this.checked = false;
							this.respuestaLiquidacionEnvio = null;
						}
						this.valLiqTemp = this.valorLiquidacionTcc;
						this.subTotal += this.valorLiquidacionTcc;
						this.grandTotal = this.subTotal + (this.subTotal * 0.19);
						this.idOrigen = this.tccService.getCodigoDANECiudadTcc(this.ciudadesHabilitadas, this.ciudadOrigen);
						this.idDestino = this.tccService.getCodigoDANECiudadTcc(this.ciudadesHabilitadas, this.ciudadDestino);
					} else {
						/* this.pesoPaquete = pesoPaquete
						this.anchoPaquete = anchoPaquete
						this.largoPaquete = largoPaquete
						this.altoPaquete = altoPaquete
						this.volumenPaquete = volumenPaquete
						this.valorLiquidacionTcc = 0
						this.subTotal += this.valorLiquidacionTcc; */
						// if (this.checked == true) { this.valorLiquidacionTcc = 0}
						// this.grandTotal += this.valorLiquidacionTcc
					}
				}
				this.loading = false;
			}, err => {
				this.respuestaLiquidacionEnvio.status = 'Error';
				console.error('ocurrio un error inesperado al comunicar con TCC!!!!!')
			});
		}, 3000);
	}
	public remove(product) {
		// TODO: RECALCULAR VALORES DE PRECIOS
		this.valorProductos = 0;
		this.subTotal = 0;
		const index: number = this.appService.Data.cartList.indexOf(product);
		const pos: number = this.productosSobreDimensionados.indexOf(product);
		if (index !== -1) {
			this.appService.Data.cartList.splice(index, 1);
			this.appService.Data.totalPrice = this.appService.Data.totalPrice  - (product.precioecommerce * product.enCarrito);
			this.appService.Data.totalCartCount = this.appService.Data.totalCartCount - product.enCarrito;
			this.appService.resetProductCartCount(product);
			this.productosSobreDimensionados.splice(pos, 1);
		}
		this.appService.Data.cartList.forEach(product => {
			this.valorProductos += product.enCarrito * (product.precioecommerce / 1.19);
		});
		this.subTotal = this.valorProductos;
		this.liquidacionSoapTcc();
	}
	realizarPago() {
		this.deshabilitarbtnpagar = true;
		this.crearPedido();
		this.horizontalStepper._steps.forEach(step => step.editable = false);
		this.verticalStepper._steps.forEach(step => step.editable = false);
		let num_ident = this.billingForm.controls.numeroDocumento.value;
		let tipo_doc = this.billingForm.controls.tipoDocumento.value;
		let nombre_usuario = this.billingForm.controls.firstName.value + ' ' + this.billingForm.controls.lastName.value;
		let correo = this.billingForm.controls.email.value;
		let telefono = this.billingForm.controls.phone.value;
		let direccion = this.billingForm.controls.address.value;
		this.ecollectService.pagar(this.grandTotal, num_ident, correo, telefono, direccion, nombre_usuario, tipo_doc);
	}
	realizarPagoV2openpay() {
		this.deshabilitarbtnpagar = true;
		this.crearPedido();
		this.horizontalStepper._steps.forEach(step => step.editable = false);
		this.verticalStepper._steps.forEach(step => step.editable = false);
	}
	realizarPagoWompi(){
		this.deshabilitarbtnpagar = true;
		this.crearPedido();
		this.horizontalStepper._steps.forEach(step => step.editable = false);
		this.verticalStepper._steps.forEach(step => step.editable = false);
	}
	crearRemesa() {
		this.tccService.grabarDespacho4Tcc(
			this.tccService.getCodigoDANECiudadTcc(this.ciudadesHabilitadas, this.ciudadOrigen),
			this.tccService.getCodigoDANECiudadTcc(this.ciudadesHabilitadas, this.ciudadDestino),
			this.billingForm.controls.address.value,
			'ojhernandez@iegrupo.co', // direccion del operario que alista los productos
			this.billingForm.controls.firstName.value,
			this.billingForm.controls.lastName.value,
			this.billingForm.controls.numeroDocumento.value,
			this.billingForm.controls.phone.value,
			this.valorMercancia,
			this.pesoPaquete,
			this.volumenPaquete,
			this.altoPaquete,
			this.largoPaquete,
			this.anchoPaquete,
			this.ciudadOrigen
		)
	}
	verRotuloRemesa() {
		this.tccService.verRotulos();
	}
	verRelacionEnvioRemesa() {
		this.tccService.verRelacionEnvio();
	}
	crearPedido() {
		let temp = this.jsonTemp
		let datosFull = {
			"infoCliente": {
				"origen": this.ciudadOrigen,
				"origenCod": this.tccService.getCodigoDANECiudadTcc(this.ciudadesHabilitadas, this.ciudadOrigen),
				"destino": this.ciudadDestino,
				"destinoCod": this.tccService.getCodigoDANECiudadTcc(this.ciudadesHabilitadas, this.ciudadDestino),
				"direccion": this.billingForm.controls.address.value,
				"nombre": this.billingForm.controls.firstName.value + " " + this.billingForm.controls.lastName.value,
				"firstName": this.billingForm.controls.firstName.value,
				"lastName": this.billingForm.controls.lastName.value,
				"documento": this.billingForm.controls.numeroDocumento.value,
				"documentoTipo": this.billingForm.controls.tipoDocumento.value,
				"telefono": this.billingForm.controls.phone.value,
				"email": this.billingForm.controls.email.value,
				"recoger_tienda": this.checked,
				"liquidacion": {
					"totalProductos": this.valorProductos, // totalProdcutos
					"cupon" : this.cuponActivo,
					"descuento_cupon": this.descuentoCupon,
					"remesa": this.valorLiquidacionTcc,
					"subtotal": this.subTotal,
					"iva": this.subTotal * 0.19,
					"granTotal": this.grandTotal
				},
				"datos_facturacion": {
					"nombre": this.billingForm.controls.firstName.value + " " + this.billingForm.controls.lastName.value,
					"firstName": this.billingForm.controls.firstName.value,
					"lastName": this.billingForm.controls.lastName.value,
					"razonSocial": this.billingForm.controls.razonSocial.value,
					"documentoTipo": this.billingForm.controls.tipoDocumento.value,
					"documento": this.billingForm.controls.numeroDocumento.value,
					"email": this.billingForm.controls.email.value,
					"telefono": this.billingForm.controls.phone.value,
					"direccion": this.billingForm.controls.address.value,
				},
				"datos_entrega": {
					"nombre": this.entregaForm.controls.firstName.value + " " + this.entregaForm.controls.lastName.value,
					"firstName": this.entregaForm.controls.firstName.value,
					"lastName": this.entregaForm.controls.lastName.value,
					"razonSocial": this.entregaForm.controls.razonSocial.value,
					"documentoTipo": this.entregaForm.controls.tipoDocumento.value,
					"documento": this.entregaForm.controls.numeroDocumento.value,
					"email": this.entregaForm.controls.email.value,
					"telefono": this.entregaForm.controls.phone.value,
					"direccion": this.entregaForm.controls.address.value,
				},
			},
			"infoPedido": this.appService.Data.cartList,
			"productosCiudades": temp,
		}
		
		let body = {
			"datos": datosFull,
			"cuenta": this.usuario.id,
			"remesa": null,
			"transaccion": null,
			"estado": 0,
			"createdAt": Date.now(),
			"updatedAt": Date.now()
		}
		this.agregarPixel3M()

		this.http.post(this.globals.urlApi + "pedidos/pedido/crear-pedido", body).subscribe( (resPedido: any) => {

			console.log('respuesta creación pedido', resPedido)

			
			/* this.http.post(this.globals.backEcollect + 'enviarCorreoPedidoAdmin.php', {datos: pedido.datos }).subscribe((res: any) => {
				console.log('Enviando a comercial', res); */
				this.http.post(this.globals.backEcollect + 'enviarCorreoCompra.php', {datos: resPedido.datos }).subscribe((res: any) => {
					console.log('Enviando a comercial v2', res);
				})
			// })
			
			this.pedidoService.pedidoCreado = resPedido;
		}, err => {
			console.log('error creación pedido', err)
		});
	}

	inicioDatos() {
		this.productosPedido = this.appService.Data.cartList;
		let productosPedidoTemp = []
		this.productosPedido.forEach((producto, index, arr) => {
			this.productoService.getProductosBodegaInfo(producto.idsap).subscribe((info) => {
				console.log("le info => ", info)
				info.forEach(elemento => {
					let temporal = elemento
					temporal.ciudad = elemento.ciudad
					temporal.bodega = elemento.bodega
					delete temporal.info_bodega
					temporal.nombre = elemento.nombre
					temporal.enCarrito = producto.enCarrito
					productosPedidoTemp.push(temporal)
				});
				if (!arr[index + 1]) {
					this.appService.agruparProductosCiudad(productosPedidoTemp, item => item.ciudad)
				}
			});
		});
	}
	reclamarEnTienda(valor: boolean) {
		this.checked = !valor;
		if (this.checked == false) {
			this.valorLiquidacionTcc = this.valLiqTemp;
			this.subTotal += this.valorLiquidacionTcc;
			this.grandTotal = this.subTotal + (this.subTotal * 0.19)
		} else {
			this.subTotal = this.subTotal - this.valorLiquidacionTcc;
			this.grandTotal = this.subTotal + (this.subTotal * 0.19)
			this.valorLiquidacionTcc = 0;
		}
	}
	mismosDatos(valor: boolean) {
		this.mismosDatosFacEnt = !valor;
		if (this.mismosDatosFacEnt) {
			this.billingForm.setValue({
				tipoDocumento: this.entregaForm.get('tipoDocumento').value,
				numeroDocumento: this.entregaForm.get('numeroDocumento').value,
				razonSocial: this.entregaForm.get('razonSocial').value,
				firstName: this.entregaForm.get('firstName').value,
				lastName: this.entregaForm.get('lastName').value,
				email: this.entregaForm.get('email').value,
				phone: this.entregaForm.get('phone').value,
				ciudad: this.entregaForm.get('ciudad').value,
				address: this.entregaForm.get('address').value,
			});
		} else {
			this.billingForm.patchValue({
				tipoDocumento: '',
				numeroDocumento: '',
				razonSocial: 'Razón social',
				firstName: (this.usuario) ? this.usuario.firstName : 'Nombres(s)',
				lastName: (this.usuario) ? this.usuario.lastName : 'Apellido(s)',
				email: [
					(this.usuario) ? this.usuario.email : '',
				],
				phone: '',
				ciudad: '',
				address: '',
			});
		}
	}
	ordenPeso(a, b) {
		if (a.peso > b.peso) {
			return -1;
		}
		if (a.peso < b.peso) {
			return 1;
		}
		return 0;
	}
	ordenarPorPeso(arreglo: any) {
		let items = Object.keys(arreglo).map(function (key) {
			return [key, arreglo[key]]
		})
		items.sort(function (uno, dos) {
			return dos[1].peso - uno[1].peso
		})
		let ordenado: any = [];
		items.forEach(item => {
			ordenado[item[0]] = item[1];
		});
		// console.log(ordenado)
		return ordenado
	}
	ordenarDiccionario(diccionario, ordenarPor, ordenNumerico, reverse) {
		ordenarPor = ordenarPor || 1;
		ordenNumerico = ordenNumerico || false;
		reverse = reverse || false;

		let reversed = (reverse) ? -1 : 1;
		let ordenado = [];
		for (const key in diccionario) {
			if (diccionario.hasOwnProperty(key)) {
				ordenado.push([key, diccionario[key]]);
			}
		}
		if (ordenNumerico) {
			ordenado.sort(function (a, b) {
				return reversed * (a[1][ordenarPor] - b[1][ordenarPor]);
			})
		} else {
			ordenado.sort(function (a, b) {
				let x = a[1][ordenarPor].toLowerCase();
				let y = b[1][ordenarPor].toLowerCase();
				return x < y ? reversed * -1 : x > y ? reversed : 0;
			})
		}
		let newObject = {}

		// let sortedArray = sortProperties(objects, 'zindex', true, false);
		for (var i = 0; i < ordenado.length; i++) {
			var key = ordenado[i][0];
			var value = ordenado[i][1];
			newObject[key] = value;
		}
		return newObject;
	}
	
	getCiudadOrigenMercancia() {
		let opciones_mejor_ciudad: any = []; // opciones de origen segun cada criterio 1 - 2
		let opciones_mejor_ciudad_peso: any = []; // opciones de origen segun cada criterio 1 - 2
		// 1 - Retornar la ciudad que tenga todos los productos
		// 2 - Retornar la ciudad con mas peso.
		// 3 - Si hay mas de una opcion de ciudad origen del envio: Retornar la ciudad mas cerca de las que tengan el mismo mayor peso
		let productos_ciudad: any = []
		let cantidad_productos = this.appService.Data.cartList.length;
		
		productos_ciudad = this.appService.productosCiudad;
		
		// Construir arreglo de ciudades disponibles para el origen del envio
		for (const ciudad in productos_ciudad) {
			if (productos_ciudad.hasOwnProperty(ciudad)) {
				if (Object.keys(productos_ciudad[ciudad]).length == cantidad_productos ) {
					let stock_en_ciudad = false
					let aux = true;
					for (const producto in productos_ciudad[ciudad]) {
						if (productos_ciudad[ciudad].hasOwnProperty(producto) && productos_ciudad[ciudad][producto].stock > 0) {
							if (productos_ciudad[ciudad][producto].stock >= productos_ciudad[ciudad][producto].enCarrito && productos_ciudad[ciudad][producto].stock > 0) {
								stock_en_ciudad = true;
							}
						}
					}
					if (stock_en_ciudad) {
						opciones_mejor_ciudad[ciudad] = (productos_ciudad[ciudad]);
					}
				}
			}
		}


		if (Object.keys(opciones_mejor_ciudad).length == 1) {
			/* if (Object.keys(opciones_mejor_ciudad)[0] == 'cartagena') {
				this.ciudadOrigen = 'cartagena de indias';
			} else { */
				this.ciudadOrigen = Object.keys(opciones_mejor_ciudad)[0];
			// }
			// console.log('solo hay una ciudad con el pedido completo: ', this.ciudadOrigen)
		} else if (Object.keys(opciones_mejor_ciudad).length > 1) {
			// console.log('varias opciones de origen, buscando la mas cercana...')
			let app = this.geocodeService.getCiudadMasCercana(opciones_mejor_ciudad, this.ciudadDestino, this.ciudadOrigen)
			// console.log("cual es la mas cercana ?", app)
			app.then(res => {
				setTimeout(() => {
					res.sort(this.geocodeService.ordenDistancia)
					this.ciudadOrigen = res[0].ciudad
					// console.log('ciudad de origen mas cercana porque hay varias bodegas con el pedido completo ==> ', this.ciudadOrigen)
				}, 1000);
			})
		} else if (Object.keys(opciones_mejor_ciudad).length == 0) {
			// console.log('no hay ciudad que contenga todos los productos, buscar por peso')
			let objetosCiudadPeso = {
				ciudad: '',
				peso: 0,
				volumen: 0,
				productos: [],
			}
			for (const ciudad in productos_ciudad) {
				if (productos_ciudad.hasOwnProperty(ciudad)) {
					let peso_ciudad = 0;
					let volumen_ciudad = 0;
					let productos_en_ciudad = productos_ciudad[ciudad];
					if (ciudad != 'peso' && ciudad != 'volumen') {
						for (const producto in productos_en_ciudad) {
							// if ( producto != 'peso' && producto != 'volumen') {
							if (productos_en_ciudad.hasOwnProperty(producto)) {
								let prod = productos_en_ciudad[producto]
								peso_ciudad += (prod.enCarrito * prod.peso)
								if ((prod.alto > 0 && prod.longitud > 0 && prod.ancho > 0) && prod.volumen == 0) {
									prod.volumen = prod.alto * prod.longitud * prod.ancho;
									volumen_ciudad += (prod.enCarrito * prod.volumen)
								} else {
									volumen_ciudad += (prod.enCarrito * prod.volumen)
								}
							}
							// }
						}
						productos_en_ciudad.peso = peso_ciudad
						productos_en_ciudad.volumen = volumen_ciudad
						objetosCiudadPeso = productos_en_ciudad
						objetosCiudadPeso.ciudad = ciudad
						opciones_mejor_ciudad_peso.push(objetosCiudadPeso)
					}
				}
			}
			let opt = Math.max.apply(Math, opciones_mejor_ciudad_peso.map(function(prev, curr) { return (prev.peso > curr.peso) ? prev : curr }))
			console.log("opt", opt)
			this.ciudadOrigen = opciones_mejor_ciudad_peso[opt].ciudad;
			console.log('ciudad de origen de la mercancia ---> ', this.ciudadOrigen, 'forma: peso');
		}
		// console.log("productos_ciudad 2", productos_ciudad)
	}
	getUsuarioBD() {
		this.cuentaService.getIdCuenta(this.ieAuthService.getCurrentUser().email).subscribe(res => {
			this.idCuenta = res[0].id;
		})
	}
	verModalEnvio(): void {
		const dialogoEnvio = this.dialogo.open(DialogoEnvioComponent, {
			width: '480px',
			data: {},
			disableClose: true
		})
	}
	agregarCupon(): void {
		let valorEnvioDescuento = 0;
		let productoEnCarritoAplicable;
		this.descuentoCupon = 0;
		const dialogRef = this.dialogo.open(DialogoCuponComponent, {
			width: '400px',
			data: {cuponActivo: this.cuponActivo, valorProductos: this.valorProductos, idCuenta: this.ieAuthService.usuario.id },
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(result => {
			this.cuponActivo = result;
			// TODO: C/U cupones
			if (this.cuponActivo) {
				if ( this.cuponActivo.envio ) {
					valorEnvioDescuento = this.valorLiquidacionTcc;
				}
				switch (this.cuponActivo.tiposDctoCpnId) {
					case "1": // porcentaje en carrito
						this.descuentoCupon = ((this.valorProductos / 100) * this.cuponActivo.valor );
						break;
					case "2": // fijo en carrito
						this.descuentoCupon = this.cuponActivo.valor;
						break;
					case "3": // porcentaje en productos
						if (this.cuponActivo.cupones_productos.length > 0) {
							this.cuponActivo.cupones_productos.forEach(productoConCupon => {
								console.log('DATA 869', this.appService.Data.cartList)
								productoEnCarritoAplicable = this.appService.Data.cartList.find(o => o.idsap.toString() == productoConCupon.idSapProducto.toString() )
								console.log('xxx 870 ', productoEnCarritoAplicable)
								if(productoEnCarritoAplicable) {
									this.descuentoCupon += ((((productoEnCarritoAplicable.precioecommerce / 1.19) * productoEnCarritoAplicable.enCarrito) / 100) * this.cuponActivo.valor)
								} else {
									this.descuentoCupon += 0;
								}
								
							});
						} else {
							this.appService.Data.cartList.forEach(productoEnCarritoAplicable => {
								console.log('xxx 875 ', productoEnCarritoAplicable)
								this.descuentoCupon += ((((productoEnCarritoAplicable.precioecommerce / 1.19) * productoEnCarritoAplicable.enCarrito) / 100) * this.cuponActivo.valor)
							})
						}
						console.log('descuento', this.descuentoCupon)
						break;
					case "4": // fijo en productos
						if (this.cuponActivo.cupones_productos.length > 0) {
							this.cuponActivo.cupones_productos.forEach(productoConCupon => {
								let productoEnCarritoAplicable = this.appService.Data.cartList.find(porcion => porcion.idsap === productoConCupon.idSapProducto )
								if (productoEnCarritoAplicable) {
									this.descuentoCupon += ((productoEnCarritoAplicable.enCarrito) * this.cuponActivo.valor)
								}
							});
						} else {
							this.appService.Data.cartList.forEach(productoEnCarritoAplicable => {
								this.descuentoCupon += ((productoEnCarritoAplicable.enCarrito) * this.cuponActivo.valor)
							})
						}
						break;
					default:
						break;
				};
			} else {
				this.descuentoCupon = 0
			}
			this.subTotal = this.valorProductos + this.valorLiquidacionTcc - valorEnvioDescuento - this.descuentoCupon;
			this.grandTotal = this.subTotal + (this.subTotal * 0.19)
		})
	}
	buscarPersonalizado(term: string, item: any) { // mejora pendiente https://github.com/ng-select/ng-select/blob/master/demo/app/examples/search.component.ts
		term = term.toLocaleLowerCase();
		return item.NOMBRE_POBLACION.toLowerCase().indexOf(term) > -1 || item.DEPARTAMENTO.toLowerCase().indexOf(term) > -1;
	}
	WhatsApp() {
		window.open('https://api.whatsapp.com/send?phone=573176488875&text=')
	}
	camposNegociacion (dias) {
		let campos = {
			nombreCampoDias: '',
			nombreCampoDesc: ''
		}
		switch (true) {
			case (dias >= 0 && dias <= 8):
				campos.nombreCampoDias = "contado";
				campos.nombreCampoDesc = 'descontado';
				break;
			case (dias >= 9 && dias <= 45):
				campos.nombreCampoDias = "treinta";
				campos.nombreCampoDesc = 'desctreinta';
				break;
			case (dias >= 46 && dias <= 60):
				campos.nombreCampoDias = "sesenta";
				campos.nombreCampoDesc = 'descsesenta';
				break;
			case (dias >= 61 && dias <= 90):
				campos.nombreCampoDias = "noventa";
				campos.nombreCampoDesc = 'descnoventa';
				break;
			case (dias > 90 && dias != 120):
				campos.nombreCampoDias = "noventamas";
				campos.nombreCampoDesc = 'descnoventamas';
				break;
			case (dias === 120):
				campos.nombreCampoDias = "cientoveinte";
				campos.nombreCampoDesc = 'desccientoveinte';
				console.log('x6');
				break;
			default:
				campos.nombreCampoDias = "contado";
				campos.nombreCampoDesc = 'descontado';
				break;
		}
		return campos;
	}
	pagarConCupo(): void{		
		const dialogRef = this.dialogo.open(DialogoPagoCupoComponent, {
			width: '34000px',
			data: {
				productosCompra: this.appService.Data.cartList,
				listadoProductosNeg: this.tempNegociacion,
				valorFlete: this.valorLiquidacionTcc,
				cupoDisponible: this.cupoDisponible
			},
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res =>{
			if(res) {
				if(res.estadoNegociacion && res.estadoNegociacion === true){
					this.crearPedidoConCupo(res.liquidacionPedidoCupo)

				}
			}
		})
	}
	crearPedidoConCupo(valoresCupo) {
		let temp = this.jsonTemp
		let datosFull = {
			"infoCliente": {
				"origen": this.ciudadOrigen,
				"origenCod": this.tccService.getCodigoDANECiudadTcc(this.ciudadesHabilitadas, this.ciudadOrigen),
				"destino": this.ciudadDestino,
				"destinoCod": this.tccService.getCodigoDANECiudadTcc(this.ciudadesHabilitadas, this.ciudadDestino),
				"direccion": this.billingForm.controls.address.value,
				"nombre": this.billingForm.controls.firstName.value + " " + this.billingForm.controls.lastName.value,
				"firstName": this.billingForm.controls.firstName.value,
				"lastName": this.billingForm.controls.lastName.value,
				"documento": this.billingForm.controls.numeroDocumento.value,
				"documentoTipo": this.billingForm.controls.tipoDocumento.value,
				"telefono": this.billingForm.controls.phone.value,
				"email": this.billingForm.controls.email.value,
				"recoger_tienda": this.checked,
				"liquidacion": valoresCupo /* {
					"totalProductos": this.valorProductos, // totalProdcutos
					"cupon" : this.cuponActivo,
					"descuento_cupon": this.descuentoCupon,
					"remesa": this.valorLiquidacionTcc,
					"subtotal": this.subTotal,
					"iva": this.subTotal * 0.19,
					"granTotal": this.grandTotal
				} */,
				"datos_facturacion": {
					"nombre": this.billingForm.controls.firstName.value + " " + this.billingForm.controls.lastName.value,
					"firstName": this.billingForm.controls.firstName.value,
					"lastName": this.billingForm.controls.lastName.value,
					"razonSocial": this.billingForm.controls.razonSocial.value,
					"documentoTipo": this.billingForm.controls.tipoDocumento.value,
					"documento": this.billingForm.controls.numeroDocumento.value,
					"email": this.billingForm.controls.email.value,
					"telefono": this.billingForm.controls.phone.value,
					"direccion": this.billingForm.controls.address.value,
				},
				"datos_entrega": {
					"nombre": this.entregaForm.controls.firstName.value + " " + this.entregaForm.controls.lastName.value,
					"firstName": this.entregaForm.controls.firstName.value,
					"lastName": this.entregaForm.controls.lastName.value,
					"razonSocial": this.entregaForm.controls.razonSocial.value,
					"documentoTipo": this.entregaForm.controls.tipoDocumento.value,
					"documento": this.entregaForm.controls.numeroDocumento.value,
					"email": this.entregaForm.controls.email.value,
					"telefono": this.entregaForm.controls.phone.value,
					"direccion": this.entregaForm.controls.address.value,
				},
			},
			"infoPedido": this.appService.Data.cartList,
			"productosCiudades": temp,
		}
		let body = {
			"datos": datosFull,
			"cuenta": this.usuario.id,
			"remesa": null,
			"transaccion": null,
			"estado": 0,
			"createdAt": Date.now(),
			"updatedAt": Date.now()
		}

		// console.log('body', body)
		this.http.put(this.globals.urlApi + "pedidos", body).subscribe((pedido: any) => {
			
			/* this.http.post(this.globals.backEcollect + 'enviarCorreoPedidoCupoAdmin.php', {datos: pedido.datos }).subscribe((res: any) => {
				console.log('Enviando a comercial', res); */
				this.http.post(this.globals.backEcollect + 'enviarCorreoCompra.php', {datos: pedido.datos }).subscribe((res: any) => {
					console.log('Enviando a comercial v2', res);
				})
			// })
			
			
			this.pedidoService.pedidoCreado = pedido;
			this.router.navigate(['cuenta/historial'])
		}, err => {
			console.log('error creación pedido', err)
		});
	}
	//openpay
	crearUsuarioOpenpay() {
		let correo = this.billingForm.controls.email.value;
		let telefono = this.billingForm.controls.phone.value;
		console.log("this.datosOpen ", this.datosOpen)

		if(this.datosOpen.idsap){
			let paquete = {
				"metodo": "crearCliente",
				"adcn":this.datosOpen.idsap,
				"nombres": this.usuario.firstName,
				"apellidos": this.usuario.lastName,
				"email": this.datosOpen.email ? this.datosOpen.email : correo,
				"telefono": this.datosOpen.telefono1 ? this.datosOpen.telefono1 : telefono ,
				"direccion": this.billingForm.controls.address.value,
				"departamento": this.ciudadDestino,
				"ciudad": this.ciudadDestino
			}
			
			this.http.post(this.globals.backOpenpay + 'openpay.php', paquete).subscribe((res: any)=> {
				if(res && res.status == 'ok') {
					let pte = { idsap : this.datosOpen.idsap, idopenpay: res.idcliente}
					this.http.put(this.globals.urlApi + 'openuser', pte).subscribe((resapi:any)=>{
						if(resapi) {
							console.log("respuesta de la creación del usuario openpay", resapi);
						}
					})
				} else {
					this.http.get(this.globals.urlApi + 'openuser/idsap/' + this.datosOpen.idsap).subscribe((usuopen:any)=> {
						if(usuopen) {
							console.log("chk-1252-xxx!");
						}
					})
				}
			});
		}
	}
	crearPagoPSE() {
		this.realizarPagoV2openpay()
		let correo = this.billingForm.controls.email.value;
		let telefono = this.billingForm.controls.phone.value;
		let requestData = {
			"metodo": 'pagoPSE',
			"nombre": this.usuario.firstName,
			"apellido": this.usuario.lastName,
			"correo": this.datosOpen ? this.datosOpen.email : correo,
			"telefono": this.datosOpen ? this.datosOpen.telefono1 : telefono ,
			"departamento": this.ciudadDestino,
			"ciudad": this.ciudadDestino,
			"direccion": this.billingForm.controls.address.value,
			"precio": this.grandTotal,
		}
		
		this.http.post(this.globals.backOpenpay + 'openpay.php', requestData).subscribe((regpago:any)=>{
			if(regpago) {
				if(regpago.status == 'ok') {
					let body = {
						'transaccion' : regpago.data.id
					}
					this.http.put(this.globals.urlApi + "pedidos/actualizar", body).subscribe((pedido: any) => {
						console.log("Pedido Actualizado!!!  -->", pedido)
					})
					this.modalPago.verModalPagos(regpago.data.url)
					//window.location.href = regpago.data.url
				}
				console.log("registro de pago", regpago);
			}
		});
		/* COMO CREAR UN PAGO PSE A UN CLIENTE YA REGISTRADO ????
		this.http.get(this.globals.urlApi + 'openuser/idsap/' + this.datosOpen.idsap).subscribe((usuopen:any)=> {
			if(usuopen) {
				let requestData = { 
					"metodo": 'pagoPSECliente',
					"valor": this.grandTotal,
					"idOpenPayCLiente": usuopen[0].idopenpay
				}
				this.http.post(this.globals.backOpenpay + 'openpay.php', requestData).subscribe((regpago:any)=>{
					if(regpago) {
						console.log("registro de pago", regpago);
					}
				});
			}
		});
		*/
	}
	crearPagoTarjetaOpenpayNoCliente() {
		this.realizarPagoV2openpay()
		let correo = this.billingForm.controls.email.value;
		let telefono = this.billingForm.controls.phone.value;
		let requestData = {
			"metodo": 'pagoConTarjetaNoCliente',
			"nombre": this.usuario.firstName,
			"apellido": this.usuario.lastName,
			"correo": this.datosOpen ? this.datosOpen.email : correo,
			"telefono": this.datosOpen ? this.datosOpen.telefono1 : telefono ,
			"precio": this.grandTotal,
		}
		this.http.post(this.globals.backOpenpay + 'openpay.php', requestData).subscribe((regpago:any)=>{
			if(regpago) {
				if(regpago.status == 'ok') {
					let body = {
						'transaccion' : regpago.data.id
					}
					this.http.put(this.globals.urlApi + "pedidos/actualizar", body).subscribe((pedido: any) => {
						console.log("Pedido Actualizado!!!  -->", pedido)
					})
					this.modalPago.verModalPagos(regpago.data.url)
					//window.location.href = regpago.data.url
				}
			}
		});
	}

	// wompi
	crearPagoWompi(){
		// this.spinner.show();
		this.deshabilitarbtnpagar = true;
		this.crearPedido();
		this.horizontalStepper._steps.forEach(step => step.editable = false);
		this.verticalStepper._steps.forEach(step => step.editable = false);
		this.wompi.pagarConWompi(parseInt(this.grandTotal.toFixed(0)), 'Pago-checkout-'+this.billingForm.controls.email.value, 'https://www.iegrupo.co/#/cuenta/anticipos', this.appService.usuarioSAP?.CARDCODE, this.ciudadOrigen);
	}

	// pixel 3M
	agregarPixel3M(){
		let arreglo_productos = this.appService.Data.cartList
		let arreglo_productos_3m = []
		let shop_id_3m = this.globals.shop_id_3m;
		let cadena_mid= ``;
		this.pixel3m = `<img src="https://www.commerce-connector.com/tracking/tracking.gif?shop=JUKihHic9YYfawvcM2Gg06UQEQwVcTXA&`
		
		arreglo_productos.forEach( el => {
			if(el.idmarca == 176){  // 176 es 3M
				cadena_mid+=`merchat[${arreglo_productos_3m.length}]=${el.idsap}&sale[${arreglo_productos_3m.length}]=${el.enCarrito}&price[${arreglo_productos_3m.length}]=${Math.round(el.precio)}`;
				arreglo_productos_3m.push(el)
			}
		})
		
		this.pixel3m+=cadena_mid
		this.pixel3m +=`" width="1" height="1" border="0"/>`;
		
		this.renderer2.createElement('div', this.pixel3m)
	}

}
