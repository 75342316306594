import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-terminos-condiciones',
	templateUrl: './terminos-condiciones.component.html',
	styleUrls: ['./terminos-condiciones.component.scss']
})
export class TerminosCondicionesComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
