import { Injectable } from '@angular/core';
/* import { WINDOW } from '@ng-toolkit/universal'; */
import { Inject } from '@angular/core';

@Injectable()
export class Globals {

	public baseServidor = window.location.protocol + '//' + window.location.hostname;
	// public urlApi = this.baseServidor + ':3000/api/'; // DEV
	public urlApi = 'https://ie-vie.com:3000/api/'; // PROD
	private urlBaseBack =  this.baseServidor + window.location.pathname + 'servicios/';

	public urlServicioTcc = 'https://clientes.tcc.com.co/servicios/liquidacionacuerdos.asmx?wsdl';
	public urlSoapEcollect = 'https://www.e-collect.com/app_express/webservice/eCollectWebservicesv2.asmx?wsdl';

	public backPDF = this.urlBaseBack + 'testing/'
	public backTcc = this.urlBaseBack + 'serviciostcc/';
	public backEcollect = this.urlBaseBack + 'serviciosEcollect/';
	public backServiciosECommerce = this.urlBaseBack + 'serviciosECommerce/';
	public backAws = this.urlBaseBack + 'serviciosAws/';
	public backOpenpay = this.urlBaseBack + 'openpay/';

	// rutas imagenes productos
	public urlImgProducto = 'https://s3.amazonaws.com/iecommerce2019/';

	// SES
	public iamUserName = 'ses-smtp-ie-test.20190924-074313';
	public sesSmtpUser = 'AKIAYH47G72Q2QHS7B7Q';
	public sesSmtpPwd  = 'BCq3VBBSB7DWJvgtUxbuOsUP6lQ3NiHPNkIMIBrfND3w';

	// eQ0@3--B49   ssh root@162.241.90.23

	// 3M
	public shop_id_3m: 'JUKihHic9YYfawvcM2Gg06UQEQwVcTXA';

	// WOMPI
	public urlWompi = '';
	public llaves_wompi = {
		llave_publica: '',
		llave_privada: '',
		llave_eventos: ''
	}

	constructor(
		/* @Inject(WINDOW) private window: Window */
	) {
		if(window?.location?.hostname === 'localhost'){ // DESARROLLO
			this.urlWompi = 'https://sandbox.wompi.co/v1/';
			this.llaves_wompi.llave_eventos = 'test_events_SXRLOea3Hms6NoV9PXQsarPvXqG6ZErZ';
			this.llaves_wompi.llave_privada = 'prv_test_OCymGfWVs5VRDSXH9LT1ix5EaZnhoRcS';
			this.llaves_wompi.llave_publica = 'pub_test_VSr6TICcIRTATfl64FMJPgE79JoxaOyL';
		} else { // PRODUCCION
			this.urlWompi = 'https://production.wompi.co/v1/';
			this.llaves_wompi.llave_eventos = 'prod_events_NlAlqoeiL7AVcgsm6mvY0PMeqYEUOvt9';
			this.llaves_wompi.llave_privada = 'prv_prod_NsecN3mPTyfp8S1EGCWSbotUBJIpF0SA';
			this.llaves_wompi.llave_publica = 'pub_prod_EoAlnkuzEHHE967lg2a22tTmVGTUzcgm';
		}
	}

	
		
}

