/* import { WINDOW } from '@ng-toolkit/universal'; */
import { Component, OnInit, ViewChild , Inject, ViewEncapsulation} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductZoomComponent } from './product-zoom/product-zoom.component';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Title } from '@angular/platform-browser';

import { Meta } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { AppService } from '../../../app.service';
import { MarcaService } from '../../../services/marca.service';
import { ProductoService } from '../../../services/producto.service';
import { IeAuthService } from '../../../services/ieauth.service';
import { TagesService } from '../../../services/tages.service';
import { Producto } from '../../../models/producto';
import { Marca } from '../../../models/marca';
import { Globals } from '../../../globals';

import SwiperCore, { SwiperOptions, Autoplay, Pagination, Navigation } from 'swiper';
SwiperCore.use([Navigation, Autoplay, Pagination]);

// splash para productos con cupon
export interface DataSplash {
	idProducto: string,
}
@Component({
	selector: 'app-splash-producto',
	templateUrl: 'productModal.html'
})
export class SplashProductoComponent {
	idSplash
	constructor(
		public splash: MatDialogRef<SplashProductoComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DataSplash,
	) {
		this.idSplash = this.data;
	}
	onNoClick(): void {
		this.splash.close();
	}
}
// fin splash para productos con cupon


@Component({
	selector: 'app-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ProductComponent implements OnInit {
	@ViewChild('zoomViewer', {static: false}) zoomViewer;

	recargarControlesStock: boolean = false
	
	config:SwiperOptions = {
		observer: true,
		slidesPerView: 4,
		spaceBetween: 10,
		keyboard: true,
		navigation: true,
		pagination: false,
		loop: true,
		preloadImages: false,
		lazy: true,
		breakpoints: {
			320: {
				slidesPerView: 1
			},
			480: {
				slidesPerView: 2
			},
			600: {
				slidesPerView: 3,
			}
		}
	};
	public tipoUsuario: any;
	public product: Producto;
	public image: any;
	public zoomImage: any;
	// private sub: any;
	public form: UntypedFormGroup;
	public relatedProducts: Array<Producto>;
	public imagen: string = 'https://s3.amazonaws.com/iecommerce2019/';
	public marcaProducto: Marca;

	public imgAwsS3: string = '';
	public listado_imagenes = [];
	infoProducto: boolean = true
	// productos_cuponera = ['121103104058', '121100105061', '137104103039', '121103104056', '121103104053', ];
	productos_cuponera = [];
	public label_nombre = '';
	public descripcion_formateada = [];
	public banners = []
	categoriasDeProducto: any[] = []

	constructor(
		/* @Inject(WINDOW) private window: Window, */
		public appService: AppService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		public dialog: MatDialog,
		public formBuilder: UntypedFormBuilder,
		public productoService: ProductoService,
		private modalTerminos: NgxSmartModalService,
		private marcaService: MarcaService,
		private ieAuthService: IeAuthService,
		public modalSplashProducto: MatDialog,
		private metaService: Meta,
		private http : HttpClient,
		private globals: Globals,
		private tagesService: TagesService,
		private readonly productSvc: ProductoService,
		private readonly tituloPage: Title
	) {
		
	}
	ngOnInit() {
		this.tipoUsuario = this.ieAuthService.getCurrentUser() ? this.ieAuthService.getCurrentUser().idTipoCuenta : 2;
		this.appService.mensajeCarga = 'Cargando información...';
		this.activatedRoute.params.subscribe(params => {
			
			let dataparams = params
			this.productoService.getProducto(dataparams.id).subscribe((data: any) => {
				this.listado_imagenes = [];
				
				if (data) {
					this.actualizarControles()
					
					this.product = data;  //TODO: cambiar data[0] a data para version 2
					this.productoService.getCategoriasProductoV2(this.product);
					
					this.getPrecio(this.product)  //TODO: activar para version 2
					this.getNuevasCategorias(this.product)

					if(this.product.descripcion != null){
						this.formatearDescripcion(this.product.descripcion)
					}
					let stock = 0
					this.label_nombre = '';
					this.label_nombre = this.product.nombre;
					this.tituloPage.setTitle(this.label_nombre)
					
					if(data?.original?.ManBtchNum === 'Y'){
						let ciudad = this.appService.ciudadActual.plano_name;
						this.http.get(`${this.globals.urlApi}v2/cortas-prducto-ciudad/${ciudad}/${this.product.idsap}`).subscribe( (resDataCortas: any) =>{
							let cants = resDataCortas.data.map(el => parseInt(el.Quantity))
							this.product.stock = Math.max(...cants)
						})
					} else {
						this.product.stock = stock
					}

					if (this.productos_cuponera.includes(this.product.idsap)) {
						this.verSplash(this.product.idsap);
					}
					this.imagen = `https://s3.amazonaws.com/iecommerce2019/${this.product['idsap']}/${this.product['idsap']}.jpg`;
					switch (this.product.idsap) {
						case '144125100023':
							this.product.precioecommerce = 157080;
							break;
						case '144125100024':
							this.product.precioecommerce = 125664;
							break;
						case '144125100022':
							this.product.precioecommerce = 141372;
							break;
						case '144125100025':
							this.product.precioecommerce = 329868;
							break;
						case '144125100018':
							this.product.precioecommerce = 141372;
							break;
						/* case '106105102018':
							this.product.precioecommerce = 124500;
							break; */
						default:
							break;
					};
					
					
					if (this.tipoUsuario != 1 && this.product.remate == true) {
						this.verTerminos();
					}
					
					this.marcaService.getMarcaIdsap(this.product.idmarca).subscribe(marcaProducto => {
						this.marcaProducto = marcaProducto[0];
						this.getBannersMarca()
					})
					
					this.product.url = this.product.url.replace(/ /g, '-' ).toLowerCase()

					this.tagesService.setTitle(this.product.nombre)
					this.tagesService.setSocialMediaTags(this.product.url, this.product.nombre, this.product.nombre, this.imagen)
					
					this.addTag()
				} else {
					this.infoProducto = false;
				}
			})
		});
	}
	ngOnDestroy () {
		// this.sub.unsubscribe();
	}
	
	addTag(){
		this.metaService.updateTag({ name: 'og:image', content: this.imagen });
	}
	iniciarChatWhatsapp(){
		window.open('https://api.whatsapp.com/send?phone=573176488875&text=')
	  }
	
	seleccionarImagen (posicion) {
		this.imagen = this.listado_imagenes[posicion];
	}
	
	public selectImage(image) {
		this.image = image;
		this.zoomImage = image;
	}
	public onMouseMove(e) {
		console.log("la e", e)
		if (window.innerWidth >= 1280) {
			let image, offsetX, offsetY, x, y, zoomer;
			image = e.currentTarget;
			offsetX = e.offsetX;
			offsetY = e.offsetY;
			x = offsetX / image.offsetWidth * 100;
			y = offsetY / image.offsetHeight * 100;
			zoomer = this.zoomViewer.nativeElement.children[0];
			if (zoomer) {
				zoomer.style.backgroundPosition = x + '% ' + y + '%';
				zoomer.style.display = 'block';
				zoomer.style.height = image.height + 'px';
				zoomer.style.width = image.width + 'px';
			}
		}
	}
	public onMouseLeave(event) {
		console.log("el evento de mouseleave", event)
		// this.zoomViewer.nativeElement.children[0].style.display = 'none';
	}
	public openZoomViewer() {
		this.dialog.open(ProductZoomComponent, {
			data: this.zoomImage,
			panelClass: 'zoom-dialog'
		});
	}
	public onSubmit(values: Object): void {
		if (this.form.valid) {
			// email sent
		}
	}
	public cambiarImagen() {
		// delete this.product.imagen;
		return this.imagen ? this.imagen : `https://logosmarcas.s3.amazonaws.com/0.png`
		// console.log('ops producto-default.jpeg')
	}
	verTerminos() {
		this.modalTerminos.getModal('infoTerminos').open()
	}
	codificar (data): string {
		let opt: string = '';
		var str = data.reduce(function(a, b) { return a + String.fromCharCode(b) }, '');
		opt = "data:image/jpg;base64," + btoa(str).replace(/.{76}(?=.)/g, '$&\n');
		return opt;
	}
	verSplash (id_producto: string) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = id_producto;
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.panelClass = 'registro-modal';
		dialogConfig.height = 'auto';
		const dialogReg = this.modalSplashProducto.open(SplashProductoComponent, dialogConfig);
	}
	formatearDescripcion(cadena: string) {
		let temp = cadena.split('@')
		this.descripcion_formateada = temp;
		for(let i = 0; i < this.descripcion_formateada.length ; i++){
			if(this.descripcion_formateada[i] == "") {
				this.descripcion_formateada.splice(i, 1);
			}
		}
	}
	getBannersMarca (){
		this.http.get(this.globals.urlApi + 'marca-banners/' + this.marcaProducto.idsap).subscribe((resdata:any) =>{
			if(resdata){
				this.banners = resdata;	
			} else {
				this.banners = [];
			}
		})
	}
	getNuevasCategorias (producto) {
		this.http.get(this.globals.urlApi + 'v2/producto-categorias/por-producto/' + producto.idsap).subscribe( (resData:any) => {
			let idscates = resData.data.map(el => el.idCategoria)
			this.http.post(this.globals.urlApi + 'v2/categorias-arreglo', {ids:idscates}).subscribe( (segData:any) => {
				this.categoriasDeProducto = segData.data
			})
		})
	}
	getPrecio (producto){
		let datapeticion = {
			itemcode: producto.idsap,
			cardcode: "f"
		}
		// console.log("CCCCC => XX ", producto)
		this.productSvc.getPrecioProducto(producto.idsap).subscribe((precios: any) => {
			
			producto.precio = precios.data.precio
			producto.descuento = precios.data.descuento
			producto.precioecommerce = precios.data.precioecommerce

			this.product.precio = precios.data.precio
			this.product.descuento = precios.data.descuento
			this.product.precioecommerce = precios.data.precioecommerce

			/* if(producto.idsap == '106105102018'){
				producto.precio = 104622
				producto.precioecommerce = 124500
			} */
		});
		/* this.http.post('https://ie-vie.com:3000/api/v2/precio-calc', datapeticion).subscribe( (resData: any) => {
			producto.precio = resData.valor_.precio
			producto.descuento = resData.valor_.descuento
			producto.precioecommerce = resData.valor_.precioecommerce
			if(producto.idsap == '106105102018'){
				producto.precio = 104622
				producto.precioecommerce = 124500
			}
		}) */
		
	}
	actualizarControles(){
		this.recargarControlesStock = !this.recargarControlesStock
	}
}
