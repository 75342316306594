import { Injectable } from '@angular/core';
/* import { MapsAPILoader } from '@agm/core'; */
import { Observable, from, of } from 'rxjs';
// import { of } from 'rxjs/observable/of';
import { tap, map, switchMap } from 'rxjs/operators';
// import { fromPromise } from 'rxjs/observable/fromPromise';
import { AppService } from '../app.service';

declare var google: any;

export class Opcion {
	ciudad: string;
	productos: any[];
	distanciaAlDestino: number;
	options: any;
}

@Injectable({
	providedIn: 'root'
})
export class GeocodeService {
	private geocoder: any;
	private distancer: any;
	distancia: number;
	ciudadMasCercana
	listadoOpcionesCercanas: any[]
	arregloCiudadesDistancias: Opcion[] = [];
	promesas: any = [];
	public misCoordenadas = {
		latitud: 0,
		longitud: 0
	};
	public lat: number;
	public lng: number;
	
	closest

	constructor(/* private mapLoader: MapsAPILoader, */ private appService: AppService) {
	}
	private initGeocoder() {
		this.geocoder = google.maps.Geocoder();
		this.distancer = google.maps.DistanceMatrixService();
	}
	/* private waitForMapsToLoad(): Observable<boolean> {
		if ( !this.geocoder || !this.distancer ) {
			return from(this.mapLoader.load())
			.pipe(
				tap(() => this.initGeocoder()),
				map(() => true)
			)
		}
		return of(true)
	} */
	/* public geocodeDireccion(ubicacion: string): Observable<any> {
		return this.waitForMapsToLoad().pipe(
			switchMap(() => {
				return new Observable(observer => {
					new google.maps.Geocoder().geocode({'address': ubicacion}, (results, status) => {
						if (status == google.maps.GeocoderStatus.OK) {
							observer.next( {
								lat: results[0].geometry.location.lat(),
								lng: results[0].geometry.location.lng()
							})
						} else {
							console.log('Geoerror -- ', results, '& status -- ', status)
							observer.next({lat: 0, lng: 0})
						}observer.complete()
					});
				})
			})
		)
	} */
	async getDistancia(origen, destino, objeto: Opcion) {
		destino += ' - Colombia';
		origen += ' - Colombia';
		await new google.maps.DistanceMatrixService().getDistanceMatrix({'origins': [origen], 'destinations': [destino], travelMode: 'DRIVING'}, async (results, status) => {
			objeto.distanciaAlDestino = await results.rows[0].elements[0].distance.value;
			objeto.options = results
		}, erro => {
			console.log('error al calcular la distancia', erro)
		} );
	}
	getCiudadMasCercana(listado: any[], ciudadDestino, ciudadOrigen): Promise<any> { // arreglar ciudades distancia
		this.arregloCiudadesDistancias = []
		this.promesas = []
		/* console.log('ciudadDestino', ciudadDestino)
		console.log('listado que llega', listado) */
		for (let llave in listado) {
			if (listado[llave]) {
				let opcion = new Opcion();
				opcion.ciudad = llave;
				opcion.productos = listado[llave];
				this.promesas.push(
					this.getDistancia(llave, ciudadDestino, opcion).then(() => {
						this.arregloCiudadesDistancias.push(opcion)
					})
				)
			}
		}
		return Promise.all(this.promesas).then(() => {
			// console.log("orden")
			let opt = this.arregloCiudadesDistancias.sort(this.ordenDistancia);
			
			return this.arregloCiudadesDistancias.sort(this.ordenDistancia);
		}).catch(razon => {
			console.log('razon', razon)
		})
	}
	ordenarArregloDistancia() {
		this.arregloCiudadesDistancias.sort(this.ordenDistancia);
	}
	ordenDistancia(a: Opcion, b: Opcion) {
		if ( (a.distanciaAlDestino < b.distanciaAlDestino ) ) {
			return -1;
		}
		if ( (a.distanciaAlDestino >= b.distanciaAlDestino ) ) {
			return 1;
		}
		return 0;
	}
	public sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
}
